<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <!-- นำทาง -->
    <v-card elevation="0" class="v-card-margin-bottom-30">
      <v-card-title class="body-1">
        ออเดอร์
        <v-icon>mdi-chevron-right</v-icon>
        รายละเอียดออเดอร์ {{ GenPA(detail_ID) }}
        <!-- <v-spacer/>
        <v-btn color="primary" @click="black_to_order_page()">ปิด</v-btn> -->
      </v-card-title>
    </v-card>

    <!-- แสดงภาพ -->
    <v-dialog v-model="ShowPhoto" max-width="700">
      <v-card class="ma-0">
        <v-card-text>
          <v-row align="center">
            <v-col cols="12" class="text-center">
              <img class="hidden-md-and-up" :src="ShowPhotoLink" width="300" />
              <img class="hidden-sm-and-down" :src="ShowPhotoLink" width="650" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn text color="#1976d2" @click="Opentap(ShowPhotoLink)">เปิดแถบใหม่</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="#1976d2" @click="ShowPhoto = false">ปิด</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- URL สำหรับลูกค้า -->
    <v-card elevation="1" class="v-card-margin-bottom-30">
      <v-card-title> URL สำหรับลูกค้า </v-card-title>
      <v-card-text class="">
        <v-row class="ma-0">
          <a :href="detail_Token" target="_blank">
            {{ detail_Token }}
            <input
              type="hidden"
              id="testing-code"
              style="width: 100%"
              v-model="detail_Token"
            />
          </a>
          <v-spacer />
          <v-btn :elevation="1" color="primary" class="pa-2" @click="copyToken()">
            <v-icon size="14" class="pr-1">fa-solid fa-copy</v-icon>
            คัดลอก URL
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- สถานะ -->
    <v-card elevation="1" class="v-card-margin-bottom-30">
      <v-card-title> สถานะ </v-card-title>
      <v-card-text class="">
        {{ detail_statusName }}
      </v-card-text>
    </v-card>

    <!-- ที่อยู่ผู้ส่ง -->
    <v-card
      v-if="!platformReferenceSourceID.includes(detail_referenceSourceID)"
      elevation="1"
      class="v-card-margin-bottom-30"
    >
      <v-card-title> ที่อยู่ผู้ส่ง </v-card-title>
      <v-card-text class="">
        <v-row class="pb-3">
          <v-col cols="4" md="3" lg="2"> ชื่อผู้ส่ง : </v-col>
          <v-col cols="8" md="9" lg="10">
            {{ detail_ShopName }}
          </v-col>
        </v-row>
        <v-row class="pb-3">
          <v-col cols="4" md="3" lg="2"> เบอร์ : </v-col>
          <v-col cols="8" md="9" lg="10">
            {{ detail_ShopPhone }}
          </v-col>
        </v-row>
        <v-row class="pb-3">
          <v-col cols="4" md="3" lg="2"> ที่อยู่ : </v-col>
          <v-col cols="8" md="9" lg="10">
            {{ detail_ShopAddress }}
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- ที่อยู่ผู้รับ -->
    <v-card
      v-if="!platformReferenceSourceID.includes(detail_referenceSourceID)"
      elevation="1"
      class="v-card-margin-bottom-30"
    >
      <v-card-title> ที่อยู่ผู้รับ </v-card-title>
      <v-card-text class="">
        <v-row class="pb-3">
          <v-col cols="4" md="3" lg="2"> ชื่อผู้รับ : </v-col>
          <v-col cols="8" md="9" lg="10">
            {{ detail_ShipName }}
          </v-col>
        </v-row>
        <v-row class="pb-3">
          <v-col cols="4" md="3" lg="2"> เบอร์ : </v-col>
          <v-col cols="8" md="9" lg="10">
            {{ detail_ShipPhone }}
          </v-col>
        </v-row>
        <v-row class="pb-3">
          <v-col cols="4" md="3" lg="2"> ที่อยู่ : </v-col>
          <v-col cols="8" md="9" lg="10">
            {{ detail_ShippingAddress }}
          </v-col>
        </v-row>
        <v-row class="pb-3">
          <v-col cols="4" md="3" lg="2"> Line : </v-col>
          <v-col cols="8" md="9" lg="10">
            {{ detail_line }}
          </v-col>
        </v-row>
        <v-row class="pb-3">
          <v-col cols="4" md="3" lg="2"> Facebook : </v-col>
          <v-col cols="8" md="9" lg="10">
            {{ detail_fackbook }}
          </v-col>
        </v-row>
        <v-row class="pb-3">
          <v-col cols="4" md="3" lg="2"> Email : </v-col>
          <v-col cols="8" md="9" lg="10">
            {{ detail_email }}
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- แพลตฟอร์ม -->
    <v-card
      v-if="orderItemTempList.length > 0"
      elevation="1"
      class="v-card-margin-bottom-30"
    >
      <v-card-title> แพลตฟอร์ม </v-card-title>
      <v-card-text>
        <v-row class="pb-3">
          <v-col cols="4" md="3" lg="2"> เลขอ้างอิง : </v-col>
          <v-col cols="8" md="9" lg="10">
            {{ detail_ReferenceNumber }}
          </v-col>
        </v-row>
        <v-row class="pb-3">
          <v-col cols="4" md="3" lg="2"> ช่องทาง : </v-col>
          <v-col cols="8" md="9" lg="10">
            <a
              v-if="
                detail_ChannelSourceLogoPic != null && detail_ChannelSourceLogoPic != ''
              "
              icon
              @click="OpenPhoto(detail_ChannelSourceLogoPic)"
            >
              <v-img
                style="margin: 5px"
                v-if="
                  detail_ChannelSourceLogoPic != '' && detail_ChannelSourceLogoPic != null
                "
                :src="detail_ChannelSourceLogoPic"
                aspect-ratio="1"
                class="grey lighten-2"
                max-width="30"
                max-height="30"
              ></v-img>
            </a>
          </v-col>
        </v-row>
        <v-row
          class="form-group"
          v-if="
            (this.detail_referenceSourceID == 3 ||
              this.detail_referenceSourceID == 4 ||
              this.detail_referenceSourceID == 11) &&
            marketplace_status != null
          "
        >
          <v-col cols="4" md="3" lg="2"> สถานะ : </v-col>
          <v-col cols="8" md="9" lg="10">
            {{ marketplace_status }}
          </v-col>
        </v-row>
        <v-row class="pb-3" v-if="detail_TempSenderAddress != null">
          <v-col cols="4" md="3" lg="2"> ผู้ส่ง : </v-col>
          <v-col cols="8" md="9" lg="10">
            {{ detail_TempSenderAddress }}
          </v-col>
        </v-row>
        <v-row class="pb-3">
          <v-col cols="4" md="3" lg="2"> ผู้รับ : </v-col>
          <v-col cols="8" md="9" lg="10">
            {{ detail_TempAddress }}
          </v-col>
        </v-row>

        <!-- PC hidden-xs-only -->
        <v-row class="pb-3">
          <v-col cols="4" md="3" lg="2"> ที่ต้องแพ็ค : </v-col>
          <v-col cols="8" md="9" lg="10" class="hidden-xs-only">
            <v-simple-table class="packhai-border-table">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center" width="90px">รูป</th>
                    <th class="text-left">ชื่อ</th>
                    <th class="text-left" width="100px">ลักษณะ</th>
                    <th class="text-left" width="100px">SKU</th>
                    <th class="text-right" width="70px">จำนวน</th>
                    <th class="text-right" width="140px">ราคาต่อหน่วย</th>
                    <th class="text-right" width="140px">รวมราคา</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in orderItemTempList" :key="item.name">
                    <td class="text-center">
                      <a
                        v-if="item.imgUrl != null && item.imgUrl != ''"
                        icon
                        @click="OpenPhoto(item.imgUrl)"
                      >
                        <v-img
                          style="margin: 5px"
                          :src="item.imgUrl"
                          aspect-ratio="1"
                          class="grey lighten-2"
                          width="50px"
                          height="50px"
                        ></v-img>
                      </a>
                    </td>
                    <td class="text-left">
                      <div v-if="item.notSku" class="color-red">
                        {{ item.name }}
                        <br />
                        ไม่พบสินค้าในระบบ
                      </div>
                      <div v-else>
                        {{ item.name }}
                      </div>
                    </td>
                    <td class="text-right">{{ item.propDescription }}</td>
                    <td class="text-left">
                      <div v-if="item.notSku" class="color-red">
                        {{ item.sku }}
                      </div>
                      <div v-else>
                        {{ item.sku }}
                      </div>
                    </td>
                    <td class="text-right">{{ item.quantity }}</td>
                    <td class="text-right">{{ format_price(item.unitPrice) }}</td>
                    <td class="text-right">
                      {{ format_price(item.unitPrice * item.quantity) }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>

        <!-- Mobile hidden-sm-and-up -->
        <v-row class="pb-3 hidden-sm-and-up">
          <v-col cols="12">
            <v-data-iterator
              v-if="orderItemTempList.length != 0"
              :items="orderItemTempList"
              :items-per-page="9999999999"
              hide-default-header
              hide-default-footer
              class="hidden-sm-and-up"
            >
              <template v-slot:default="props">
                <v-row class="ma-0">
                  <v-col
                    v-for="(item, index) in props.items"
                    :key="index"
                    cols="12"
                    class="pa-1"
                  >
                    <v-card>
                      <v-card-title class="subheading font-weight-bold pa-0">
                        <div class="px-0 v-list-item item-stert">
                          <a
                            v-if="item.imgUrl != null && item.imgUrl != ''"
                            @click="OpenPhoto(item.imgUrl)"
                            class="v-avatar v-list-item__avatar my-0 mx-4 rounded-0 v-avatar--tile"
                          >
                            <img :src="item.imgUrl" class="picsize" />
                          </a>
                          <a
                            v-else
                            class="v-avatar v-list-item__avatar my-0 mx-3 rounded-0 v-avatar--tile"
                            style="height: 90px; min-width: 90px; width: 90px"
                          >
                            <span class="bold-color-gray-12"> No Picture </span>
                          </a>
                          <div class="v-list-item__content pa-2 pl-0">
                            <div
                              class="v-list-item__title subtitle-1 packhai-showproduct-2"
                            >
                              <div>
                                <div>ชื่อ</div>
                                <div style="line-height: 1rem !important">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <div v-bind="attrs" v-on="on" class="cut-text">
                                        <span v-if="item.name != null">
                                          <div v-if="item.notSku" class="color-red">
                                            : {{ item.name }}
                                          </div>
                                          <div v-else>: {{ item.name }}</div>
                                        </span>
                                      </div>
                                    </template>
                                    <span v-if="item.name != null">{{ item.name }}</span>
                                  </v-tooltip>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div class="v-markdown subtitle-1 packhai-showproduct-2">
                                <div>
                                  <div>ลักษณะ</div>
                                  <div class="cut-text">: {{ item.propDescription }}</div>
                                </div>
                                <br />
                                <div>
                                  <div>SKU</div>
                                  <div class="cut-text">
                                    <div v-if="item.notSku" class="color-red">
                                      : {{ item.sku }}
                                    </div>
                                    <div v-else>: {{ item.sku }}</div>
                                  </div>
                                </div>

                                <br />
                                <div>
                                  <div>จำนวน</div>
                                  <div class="cut-text">: {{ item.quantity }}</div>
                                </div>

                                <br />
                                <div>
                                  <div>ราคาต่อหน่อย</div>
                                  <div class="cut-text">: {{ item.unitPrice }}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-card-title>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
            </v-data-iterator>
          </v-col>
        </v-row>

        <v-row align="end" style="height: 30px">
          <v-col cols="8" class="py-0" align="end"><strong>ราคารวม :</strong></v-col>
          <v-col cols="4" class="py-0" align="end">
            <span v-if="detail_order.totalPrice != null"
              >{{ format_price(detail_order.totalPrice) }} บาท
            </span>
            <span v-else> 0 บาท</span>
          </v-col>
        </v-row>

        <v-row align="end" style="height: 30px">
          <v-col cols="8" class="py-0" align="end"
            ><strong>ส่วนลดออเดอร์ :</strong></v-col
          >
          <v-col cols="4" class="py-0" align="end">
            <span v-if="detail_order.orderDiscount != null"
              >{{ format_price(detail_order.orderDiscount * -1) }} บาท</span
            >
            <span v-else> 0 บาท</span>
          </v-col>
        </v-row>
        <v-row align="end" style="height: 30px">
          <v-col cols="8" class="py-0" align="end"
            ><strong>ส่วนลด Seller voucher :</strong></v-col
          >
          <v-col cols="4" class="py-0" align="end">
            <span v-if="detail_order.totalSellerDiscount != null"
              >{{ format_price(detail_order.totalSellerDiscount * -1) }} บาท</span
            >
            <span v-else> 0 บาท</span>
          </v-col>
        </v-row>
        <v-row align="end" style="height: 30px">
          <v-col cols="8" class="py-0" align="end"><strong>ค่าจัดส่ง :</strong></v-col>
          <v-col cols="4" class="py-0" align="end">
            <span v-if="detail_order.deliveryCost != null"
              >{{ format_price(detail_order.deliveryCost) }} บาท</span
            >
            <span v-else> 0 บาท</span>
          </v-col>
        </v-row>
        <v-row align="end" style="height: 30px">
          <v-col cols="8" class="py-0" align="end"
            ><strong> ส่วนลดค่าจัดส่ง :</strong></v-col
          >
          <v-col cols="4" class="py-0" align="end">
            <span v-if="detail_order.sellerDeliveryDiscount != null"
              >{{ format_price(detail_order.sellerDeliveryDiscount * -1) }} บาท</span
            >
            <span v-else> 0 บาท</span>
          </v-col>
        </v-row>
        <v-row align="end" style="height: 30px">
          <v-col
            v-if="detail_VATType == 0 || detail_VATType == 1"
            cols="8"
            class="py-0"
            align="end"
            ><strong> VAT (No VAT) :</strong></v-col
          >
          <v-col v-if="detail_VATType == 2" cols="8" class="py-0" align="end"
            ><strong> VAT (Included) :</strong></v-col
          >
          <v-col v-if="detail_VATType == 3" cols="8" class="py-0" align="end"
            ><strong> VAT (Excluded) :</strong></v-col
          >
          <v-col cols="4" class="py-0" align="end">
            <span v-if="this.detail_VATAmount != null"
              >{{ format_price(this.detail_VATAmount) }} บาท</span
            >
            <span v-else> 0 บาท</span>
          </v-col>
        </v-row>
        <v-row align="end" style="height: 30px">
          <v-col cols="8" class="py-0" align="end"><strong>รวมทั้งหมด : :</strong></v-col>
          <v-col cols="4" class="py-0" align="end">
            <span>{{ format_price(detail_order.orderTotalAmount) }} บาท</span>
          </v-col>
        </v-row>
        <v-row
          v-if="
            detail_order.totalPlatformDiscount != 0 &&
            detail_order.totalPlatformDiscount != null
          "
          align="end"
          style="height: 30px"
        >
          <v-col cols="12" class="py-0" align="end" style="color: grey">
            **ออเดอร์นี้มีส่วนลดจาก platform อีก
            {{ format_price(detail_order.totalPlatformDiscount) }} บาท
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- รูปแบบพัสดุ -->
    <v-card elevation="1" class="v-card-margin-bottom-30">
      <v-card-title> รูปแบบพัสดุ </v-card-title>
      <v-card-text class="">
        <v-row>
          <v-col cols="12">
            <span v-if="detail_IsCOD == false">
              <v-chip color="info" outlined> No-COD </v-chip>
            </span>
            <span v-else>
              <v-chip class="mr-5" color="error"> COD </v-chip>
              <span v-if="detail_CODAmount != null && detail_CODAmount != ''">
                ยอด {{ formatMoney(detail_CODAmount) }} บาท
              </span>
              <span class="pt-1">
                / ธนาคาร : {{ detail_BankName }} {{ detail_AccountName }}
                {{ detail_AccountNumber }}
              </span>
            </span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- สินค้าที่สั่ง -->
    <v-card elevation="1" class="v-card-margin-bottom-30">
      <v-card-title> สินค้าที่สั่ง </v-card-title>

      <v-card-text>
        <!-- PC hidden-xs-only -->
        <v-row class="hidden-xs-only">
          <v-col style="background: #fff" cols="12">
            <v-simple-table
              v-if="detailOrderProduct.length != 0"
              class="packhai-border-table"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center" width="120px">ภาพ</th>
                    <th class="text-left">สินค้า</th>
                    <th class="text-left">บาร์โค้ด</th>
                    <th class="text-left">SKU</th>
                    <th class="text-right">จำนวน</th>
                    <th class="text-right">ราคาต่อชิ้น</th>
                    <th class="text-right">ส่วนลด</th>
                    <th class="text-right">ราคารวม</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item2 in detailOrderProduct" :key="item2.productName">
                    <td class="text-center">
                      <div align="center">
                        <a
                          v-if="item2.photoLink != null && item2.photoLink != ''"
                          icon
                          @click="OpenPhoto(item2.photoLink)"
                        >
                          <v-img
                            style="margin: 5px"
                            :src="item2.photoLink"
                            aspect-ratio="1"
                            class="grey lighten-2"
                            width="50"
                            height="50"
                          ></v-img>
                        </a>
                      </div>
                    </td>
                    <td class="text-left">
                      {{ item2.productName }}
                      <span class="fn-11 color-b3">
                        {{
                          (item2.prop1Description != null ? item2.prop1Description : "") +
                          (item2.prop2Description != null
                            ? " , " + item2.prop2Description
                            : "")
                        }}
                      </span>
                    </td>
                    <td class="text-left">{{ item2.barcode }}</td>
                    <td class="text-left">{{ item2.sku }}</td>
                    <td class="text-right">{{ item2.quantity }}</td>
                    <td class="text-right">{{ item2.unitPrice }}</td>
                    <td class="text-right">{{ item2.discount }}</td>
                    <td class="text-right">
                      <span v-if="item2.quantity != null">{{
                        format_price(item2.unitPrice * item2.quantity - item2.discount)
                      }}</span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div v-else>-</div>
          </v-col>
        </v-row>

        <!-- Mobile hidden-sm-and-up -->
        <v-data-iterator
          v-if="detailOrderProduct.length != 0"
          :items="detailOrderProduct"
          :items-per-page="9999999999"
          hide-default-header
          hide-default-footer
          class="hidden-sm-and-up"
        >
          <template v-slot:default="props">
            <v-row class="ma-0">
              <v-col
                v-for="(item, index) in props.items"
                :key="index"
                cols="12"
                class="pa-1"
              >
                <v-card>
                  <v-card-title class="subheading font-weight-bold pa-0">
                    <div class="px-0 v-list-item item-stert">
                      <a
                        v-if="item.photoLink != null && item.photoLink != ''"
                        @click="OpenPhoto(item.photoLink)"
                        class="v-avatar v-list-item__avatar my-0 mx-4 rounded-0 v-avatar--tile"
                      >
                        <img :src="item.photoLink" class="picsize" />
                      </a>
                      <a
                        v-else
                        class="v-avatar v-list-item__avatar my-0 mx-3 rounded-0 v-avatar--tile"
                        style="height: 90px; min-width: 90px; width: 90px"
                      >
                        <span class="bold-color-gray-12"> No Picture </span>
                      </a>
                      <div class="v-list-item__content pa-2 pl-0">
                        <div class="v-list-item__title subtitle-1 packhai-showproduct-2">
                          <div>
                            <div>ชื่อ</div>
                            <div style="line-height: 1rem !important">
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <div v-bind="attrs" v-on="on" class="cut-text">
                                    <span v-if="item.productName != null"
                                      >: {{ item.productName }}</span
                                    >
                                  </div>
                                </template>
                                <span v-if="item.productName != null">{{
                                  item.productName
                                }}</span>
                              </v-tooltip>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class="v-markdown subtitle-1 packhai-showproduct-2">
                            <div>
                              <div>ลักษณะ</div>
                              <div class="cut-text">
                                :
                                {{
                                  (item.prop1Description != null
                                    ? item.prop1Description
                                    : "") +
                                  (item.prop2Description != null
                                    ? " , " + item.prop2Description
                                    : "")
                                }}
                              </div>
                            </div>
                            <br />
                            <div>
                              <div>Barcode</div>
                              <div class="cut-text">: {{ item.barcode }}</div>
                            </div>

                            <br />
                            <div>
                              <div>SKU</div>
                              <div class="cut-text">: {{ item.sku }}</div>
                            </div>

                            <br />
                            <div>
                              <div>จำนวน</div>
                              <div class="cut-text">: {{ item.quantity }}</div>
                            </div>

                            <br />
                            <div>
                              <div>ราคาต่อชิ้น</div>
                              <div class="cut-text">: {{ item.unitPrice }}</div>
                            </div>

                            <br />
                            <div>
                              <div>ส่วนลด</div>
                              <div class="cut-text">: {{ item.discount }}</div>
                            </div>

                            <br />
                            <div>
                              <div>ราคารวม</div>
                              <div class="cut-text">
                                :
                                <span v-if="item.quantity != null">{{
                                  format_price(
                                    item.unitPrice * item.quantity - item.discount
                                  )
                                }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>

        <v-row
          v-if="
            orderItemTempList.length == 0 ||
            !platformReferenceSourceID.includes(this.detail_referenceSourceID)
          "
        >
          <v-col cols="12" md="6" />
          <v-col cols="12" md="6">
            <v-row class="pt-3 pr-10">
              <v-col cols="6" align="end" class="pb-2">
                <span
                  v-if="
                    detail_VATType == null || detail_VATType == 0 || detail_VATType == 1
                  "
                  >ราคาสินค้า :</span
                >
                <span v-if="detail_VATType == 2">ราคาสินค้า : </span>
                <span v-if="detail_VATType == 3">ราคายังไม่รวมภาษี</span>
              </v-col>
              <v-col cols="6" align="end" class="pb-2">
                <span v-if="detail_totalPrice != null"
                  >{{ format_price(detail_totalPrice) }} บาท
                </span>
                <span v-else> 0 บาท</span>
              </v-col>
              <v-col cols="6" align="end" class="pb-2"> ค่าส่ง : </v-col>
              <v-col cols="6" align="end" class="pb-2">
                <span v-if="detail_DeliveryCost != null"
                  >{{ format_price(detail_DeliveryCost) }} บาท</span
                >
                <span v-else> 0 บาท</span>
              </v-col>
              <v-col cols="6" align="end" class="pb-2"> ส่วนลด : </v-col>
              <v-col cols="6" align="end" class="pb-2">
                <span v-if="detail_DeliveryCost != null"
                  >{{ format_price(detail_OrderDiscount * -1) }} บาท</span
                >
                <span v-else> 0 บาท</span>
              </v-col>
              <v-col cols="6" align="end" class="pb-2">
                <span v-if="detail_VATType == 1">ภาษีมูลค่าเพิ่ม(No VAT) :</span>
                <span v-else-if="detail_VATType == 2"
                  >ภาษีมูลค่าเพิ่ม(VAT Included) :</span
                >
                <span v-else-if="detail_VATType == 3"
                  >ภาษีมูลค่าเพิ่ม(VAT Excluded) :</span
                >
                <span v-else>ภาษีมูลค่าเพิ่ม(No VAT) :</span>
              </v-col>
              <v-col cols="6" align="end">
                <span v-if="detail_DeliveryCost != null"
                  >{{ format_price(detail_VATAmount) }} บาท</span
                >
                <span v-else> 0 บาท </span>
              </v-col>
              <v-col cols="6" align="end" class="pb-2"> รวมทั้งหมด : </v-col>
              <v-col cols="6" align="end" class="pb-2">
                <span
                  v-if="
                    detail_sum_unintPrice_and_DeliveryCost != null && detail_VATType == 3
                  "
                  >{{
                    format_price(
                      parseFloat(detail_totalPrice) +
                        parseFloat(detail_DeliveryCost) +
                        parseFloat(detail_VATAmount) -
                        parseFloat(detail_OrderDiscount)
                    )
                  }}
                  บาท</span
                >
                <span
                  v-else-if="
                    detail_sum_unintPrice_and_DeliveryCost != null && detail_VATType == 2
                  "
                  >{{
                    format_price(
                      parseFloat(detail_totalPrice) +
                        parseFloat(detail_DeliveryCost) -
                        parseFloat(detail_OrderDiscount)
                    )
                  }}
                  บาท</span
                >
                <span v-else-if="detail_sum_unintPrice_and_DeliveryCost != null"
                  >{{
                    format_price(
                      parseFloat(detail_totalPrice) +
                        parseFloat(detail_DeliveryCost) -
                        parseFloat(detail_OrderDiscount)
                    )
                  }}
                  บาท
                </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- สินค้าที่แพ็ค -->
    <v-card elevation="1" class="v-card-margin-bottom-30">
      <v-card-title> สินค้าที่แพ็ค </v-card-title>
      <v-card-text>
        <!-- PC hidden-xs-only -->
        <v-row class="hidden-xs-only">
          <v-col cols="12">
            <div class="mb-5" v-for="pac in orderMasterPackageList" :key="pac.id">
              <b>Package #{{ pac.packageNo }}</b>

              <v-simple-table
                v-if="pac.itemList.length != 0"
                class="packhai-border-table"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center" width="120px">ภาพ</th>
                      <th class="text-left">สินค้า</th>
                      <th class="text-left">บาร์โค้ด</th>
                      <th class="text-left">SKU</th>
                      <th v-if="IsHasSerialNo" class="text-left">Serial No.</th>
                      <th class="text-right" width="120px">จำนวนต้องแพ็ค</th>
                      <th class="text-right" width="120px">จำนวนที่แพ็คจริง</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item2 in pac.itemList" :key="item2.id">
                      <td class="text-center">
                        <div align="center">
                          <a
                            v-if="item2.photoLink != null && item2.photoLink != ''"
                            icon
                            @click="OpenPhoto(item2.photoLink)"
                          >
                            <v-img
                              style="margin: 5px"
                              :src="item2.photoLink"
                              aspect-ratio="1"
                              class="grey lighten-2"
                              width="50"
                              height="50"
                            ></v-img>
                          </a>
                        </div>
                      </td>
                      <td class="text-left">
                        {{ item2.productName }}
                        <span class="fn-11 color-b3">
                          {{
                            (item2.prop1Description != null
                              ? item2.prop1Description
                              : "") +
                            (item2.prop2Description != null
                              ? " , " + item2.prop2Description
                              : "")
                          }}
                        </span>
                      </td>
                      <td class="text-left">{{ item2.barcode }}</td>
                      <td class="text-left">{{ item2.sku }}</td>
                      <td v-if="IsHasSerialNo" class="text-left">
                        {{ item2.serialNumber }}
                      </td>
                      <td class="text-right" width="120px">
                        {{ formatMoney(item2.quantity) }}
                      </td>
                      <td class="text-right" width="120px">
                        {{ formatMoney(item2.packedQuantity) }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div v-else>-</div>
            </div>
          </v-col>
        </v-row>

        <!-- Mobile hidden-sm-and-up -->
        <div
          class="mb-5 hidden-sm-and-up"
          v-for="pac in orderMasterPackageList"
          :key="pac.id"
        >
          <b>Package #{{ pac.packageNo }}</b>
          <v-data-iterator
            v-if="pac.itemList.length != 0"
            :items="pac.itemList"
            :items-per-page="9999999999"
            hide-default-header
            hide-default-footer
          >
            <template v-slot:default="props">
              <v-row class="ma-0">
                <v-col
                  v-for="(item, index) in props.items"
                  :key="index"
                  cols="12"
                  class="pa-1"
                >
                  <v-card>
                    <v-card-title class="subheading font-weight-bold pa-0">
                      <div class="px-0 v-list-item item-stert">
                        <a
                          v-if="item.photoLink != null && item.photoLink != ''"
                          @click="OpenPhoto(item.photoLink)"
                          class="v-avatar v-list-item__avatar my-0 mx-4 rounded-0 v-avatar--tile"
                        >
                          <img :src="item.photoLink" class="picsize" />
                        </a>
                        <a
                          v-else
                          class="v-avatar v-list-item__avatar my-0 mx-3 rounded-0 v-avatar--tile"
                          style="height: 90px; min-width: 90px; width: 90px"
                        >
                          <span class="bold-color-gray-12"> No Picture </span>
                        </a>
                        <div class="v-list-item__content pa-2 pl-0">
                          <div
                            class="v-list-item__title subtitle-1 packhai-showproduct-2"
                          >
                            <div>
                              <div>ชื่อ</div>
                              <div style="line-height: 1rem !important">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on" class="cut-text">
                                      <span v-if="item.productName != null"
                                        >: {{ item.productName }}</span
                                      >
                                    </div>
                                  </template>
                                  <span v-if="item.productName != null">{{
                                    item.productName
                                  }}</span>
                                </v-tooltip>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div class="v-markdown subtitle-1 packhai-showproduct-2">
                              <div>
                                <div>Barcode</div>
                                <div class="cut-text">: {{ item.barcode }}</div>
                              </div>

                              <br />
                              <div>
                                <div>SKU</div>
                                <div class="cut-text">: {{ item.sku }}</div>
                              </div>

                              <br />
                              <div>
                                <div>ต้องแพ็ค</div>
                                <div class="cut-text">: {{ item.quantity }}</div>
                              </div>

                              <br />
                              <div>
                                <div>ที่แพ็คจริง</div>
                                <div class="cut-text">: {{ item.packedQuantity }}</div>
                              </div>
                              <br v-if="IsHasSerialNo" />
                              <div v-if="IsHasSerialNo">
                                <div>Serial Number</div>
                                <div class="cut-text">: {{ item.serialNumber }}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-card-title>
                  </v-card>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
        </div>
      </v-card-text>
    </v-card>

    <!-- หมายเหตุ -->
    <v-card elevation="1" class="v-card-margin-bottom-30">
      <v-card-title> หมายเหตุ </v-card-title>
      <v-card-text class="">
        {{ detail_Remark }}
      </v-card-text>
    </v-card>

    <!-- สถานะออเดอร์ -->
    <v-card elevation="1" class="v-card-margin-bottom-30">
      <v-card-title> สถานะออเดอร์ </v-card-title>
      <v-card-text class="">
        <v-row
          class="pb-3"
          v-for="(item, index) in order_status_history_list"
          :key="index"
        >
          <v-col cols="4" md="3" lg="2"> {{ item.updateDateTime }} </v-col>
          <v-col cols="8" md="9" lg="10">
            {{ item.description }}
            <span v-if="item.packName != null"> ({{ item.packName }}) </span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- ข้อมูลการขาย -->
    <v-card elevation="1" class="v-card-margin-bottom-30">
      <v-card-title> ข้อมูลการขาย </v-card-title>
      <v-card-text class="">
        <v-row class="pb-3">
          <v-col cols="4" md="3" lg="2"> เปิดออเดอร์โดย : </v-col>
          <v-col cols="8" md="9" lg="10">
            {{ detail_order.createdByStaffShopName }}
          </v-col>
        </v-row>
        <v-row class="pb-3">
          <v-col cols="4" md="3" lg="2"> พนักงานขาย : </v-col>
          <v-col cols="8" md="9" lg="10">
            {{ detail_order.salemanName }}
          </v-col>
        </v-row>
        <v-row class="pb-3">
          <v-col cols="4" md="3" lg="2"> ช่องทาง : </v-col>
          <v-col cols="8" md="9" lg="10">
            <v-avatar v-if="detail_order.channelLogo != null" size="25px" class="mr-2"
              ><img :src="detail_order.channelLogo"
            /></v-avatar>
          </v-col>
        </v-row>
        <v-row class="pb-3" v-if="detailOrderProductPlatform.length == 0">
          <v-col cols="4" md="3" lg="2"> เลขอ้างอิง : </v-col>
          <v-col cols="8" md="9" lg="10">
            {{ detail_order.referenceNumber }}
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- แพ็คกล่อง -->
    <div class="mb-5" v-for="pac in orderMasterPackageList" :key="pac.id">
      <v-card elevation="1" class="v-card-margin-bottom-30">
        <v-card-title>
          รายละเอียดพัสดุ #{{ pac.packageNo }}
          <v-spacer />
          <v-btn icon class="ma-0" @click="OpenEditBoxDialog(pac)"
            ><v-icon size="18">fa-edit</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-row class="pb-3">
            <v-col cols="6" md="3" lg="2"> น้ำหนัก : </v-col>
            <v-col cols="6" md="9" lg="10">
              <span v-if="pac.weight != null">{{ pac.weight }} กิโลกรัม</span>
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="6" md="3" lg="2"> กว้าง x ยาว x สูง : </v-col>
            <v-col cols="6" md="9" lg="10">
              <span v-if="pac.cubic1 != null && pac.cubic2 != null && pac.cubic3 != null">
                {{ pac.cubic1 + " x " + pac.cubic2 + " x " + pac.cubic3 }}
              </span>
              <span v-if="pac.boxName != null && pac.boxName != ''">
                / กล่อง {{ pac.boxName }}
              </span>
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="6" md="3" lg="2"> ค่ากล่อง : </v-col>
            <v-col cols="6" md="9" lg="10">
              <span v-if="pac.boxPrice != null">{{ pac.boxPrice }} บาท </span>
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="6" md="3" lg="2"> ค่าแพ็ค : </v-col>
            <v-col cols="6" md="9" lg="10">
              <span v-if="pac.packPrice != null">{{ pac.packPrice }} บาท </span>
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="6" md="3" lg="2"> ค่าขนส่ง : </v-col>
            <v-col cols="6" md="9" lg="10">
              <span v-if="pac.deliveryPrice != null">{{ pac.deliveryPrice }} บาท </span>
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="6" md="3" lg="2"> % COD : </v-col>
            <v-col cols="6" md="9" lg="10">
              <span v-if="pac.codPercent != null">{{ pac.codPercent }} % </span>
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="6" md="3" lg="2"> ค่าบริการ COD : </v-col>
            <v-col cols="6" md="9" lg="10">
              <span v-if="pac.codCharged != null">{{ pac.codCharged }} บาท </span>
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="6" md="3" lg="2"> พื้นที่ห่างไกล : </v-col>
            <v-col cols="6" md="9" lg="10">
              <span v-if="pac.remotePrice != null">{{ pac.remotePrice }} บาท </span>
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="6" md="3" lg="2"> ส่วนลด : </v-col>
            <v-col cols="6" md="9" lg="10">
              <span v-if="pac.discount != null">{{ pac.discount }} บาท </span>
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="6" md="3" lg="2"> ต้นทุนค่ากล่อง : </v-col>
            <v-col cols="6" md="9" lg="10">
              <span
                v-if="
                  pac.branchBoxCost != null &&
                  pac.branchBoxCost != '' &&
                  pac.branchBoxCost != 0
                "
                >{{ pac.branchBoxCost }} บาท
              </span>
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="6" md="3" lg="2"> ต้นทุนค่าขนส่ง : </v-col>
            <v-col cols="6" md="9" lg="10">
              <span v-if="pac.branchDeliveryCost != null"
                >{{ pac.branchDeliveryCost }} บาท
              </span>
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="6" md="3" lg="2"> ต้นทุนค่าบริการ COD : </v-col>
            <v-col cols="6" md="9" lg="10">
              <span v-if="pac.BranchCODChargedCost != null"
                >{{ pac.BranchCODChargedCost }} บาท
              </span>
            </v-col>
          </v-row>
          <v-divider class="pb-3"> </v-divider>
          <v-row class="pb-3">
            <v-col cols="6" md="3" lg="2"> ขนส่ง : </v-col>
            <v-col cols="6" md="9" lg="10">
              <div v-if="pac.trackStatus != null" align="left">
                <v-img
                  v-if="pac.trackStatus.expressLogo != null"
                  max-height="10vh"
                  max-width="10vh"
                  :src="pac.trackStatus.expressLogo"
                >
                </v-img>
              </div>
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="6" md="3" lg="2"> Tracking No : </v-col>
            <v-col cols="6" md="9" lg="10">
              <div>
                {{ pac.trackStatus.trackingNo }}
                <div class="pb-2" />
                <div v-if="pac.trackStatus != null" class="hidden-sm-and-down">
                  <div
                    v-if="
                      pac.trackStatus.status != null && pac.trackStatus.status.length > 0
                    "
                  >
                    <div v-for="(item, index) in pac.trackStatus.status" :key="index">
                      <span>
                        {{ formatDatetime(item.date) + " " + item.description }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <div v-if="pac.trackStatus != null" class="hidden-sm-and-up pb-3">
            <div
              v-if="pac.trackStatus.status != null && pac.trackStatus.status.length > 0"
            >
              <v-row
                class="pb-2"
                v-for="(item, index) in pac.trackStatus.status"
                :key="index"
              >
                <v-col cols="6" md="3" lg="2">
                  <span> {{ formatDatetime(item.date) }} </span>
                </v-col>
                <v-col cols="6" md="9" lg="10">
                  <span> {{ item.description }}</span>
                </v-col>
              </v-row>
            </div>
          </div>
          <v-row class="pb-3">
            <v-col cols="6" md="3" lg="2"> สถานะพัสดุ : </v-col>
            <v-col cols="6" md="9" lg="10">
              <div
                v-if="pac.trackStatus != null && pac.trackStatus.packageStatusName != ''"
              >
                <span v-if="pac.trackStatus.packageStatusID == 80" style="color: purple"
                  >{{ pac.trackStatus.packageStatusName }}
                </span>
                <span
                  v-else-if="pac.trackStatus.packageStatusID == 90"
                  style="color: orange"
                  >{{ pac.trackStatus.packageStatusName }}
                </span>
                <span
                  v-else-if="pac.trackStatus.packageStatusID == 100"
                  style="color: #ff7ac0"
                  >{{ pac.trackStatus.packageStatusName }}
                </span>
                <span
                  v-else-if="pac.trackStatus.packageStatusID == 110"
                  style="color: #ff7ac0"
                  >{{ pac.trackStatus.packageStatusName }}
                </span>
                <span
                  v-else-if="pac.trackStatus.packageStatusID == 120"
                  style="color: #ff7ac0"
                  >{{ pac.trackStatus.packageStatusName }}
                </span>
                <span
                  v-else-if="pac.trackStatus.packageStatusID == 130"
                  style="color: green"
                  >{{ pac.trackStatus.packageStatusName }}
                </span>
                <span
                  v-else-if="pac.trackStatus.packageStatusID == 140"
                  style="color: red"
                  >{{ pac.trackStatus.packageStatusName }}
                </span>
                <span v-else>{{ pac.trackStatus.packageStatusName }}</span>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- แก้ไข -->
      </v-card>
    </div>
    <!-- พนักงานแพ็ค -->
    <v-card elevation="1" class="v-card-margin-bottom-30">
      <v-card-title> พนักงานแพ็ค </v-card-title>
      <v-card-text class="">
        <v-row class="pb-3">
          <v-col cols="4" md="3" lg="2"> เริ่มแพ็ค : </v-col>
          <v-col cols="8" md="9" lg="10">
            {{ formatDatetime(detail_order.startPackingDate) }}
          </v-col>
        </v-row>
        <v-row class="pb-3">
          <v-col cols="4" md="3" lg="2"> แพ็คเสร็จ : </v-col>
          <v-col cols="8" md="9" lg="10">
            {{ formatDatetime(detail_order.packedDate) }}
          </v-col>
        </v-row>
        <v-row class="pb-3">
          <v-col cols="4" md="3" lg="2"> แพ็คโดย : </v-col>
          <v-col cols="8" md="9" lg="10">
            {{ detail_order.packedByStaffBranchName }}
          </v-col>
        </v-row>
        <v-row class="pb-3">
          <v-col cols="4" md="3" lg="2"> ใช้เวลา : </v-col>
          <v-col cols="8" md="9" lg="10">
            {{ CalculateTimeUsed() }}
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- วันที่เงินเข้า -->
    <v-card
      elevation="1"
      class="v-card-margin-bottom-30"
      v-if="detail_RealTransferDatetime != null"
    >
      <v-card-title> วันที่เงินเข้า </v-card-title>
      <v-card-text class="">
        <v-row class="pb-3">
          <v-col cols="12"> {{ detail_RealTransferDatetime }} </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- รูปแพ็คสินค้า -->
    <v-card
      elevation="1"
      class="v-card-margin-bottom-30"
      v-if="PackImage != null && PackImage.length > 0"
    >
      <v-card-title> รูปแพ็คสินค้า </v-card-title>
      <v-card-text class="">
        <v-row dense>
          <v-col
            :cols="6"
            :md="3"
            :xl="2"
            v-for="(item, index) in PackImage"
            :key="index"
            class="pa-1"
          >
            <a
              v-if="item.pic != null && item.pic != ''"
              icon
              @click="OpenPhoto(item.pic)"
            >
              <v-img
                :src="item.pic"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              />
            </a>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="edit_box_dialog"
      scrollable
      max-width="70%"
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-card-title>
          <!-- this.my_express.expressLogoDialog  -->
          <span>แก้ไขพัสดุ #{{ update_data_pack.packageNo }}</span>
          <v-spacer />
          <v-btn icon @click="edit_box_dialog = false"
            ><v-icon color="danger">mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-row class="pb-3" v-if="detail_InvoiceGroupID != null">
            <v-col cols="12">
              <span style="color: red">
                *** ออเดอร์นี้มีการเรียกเก็บไปแล้ว
                การแก้ไขข้อมูลส่วนนี้จะไม่มีการแก้ไขยอดเรียกเก็บใหม่ </span
              ><br />
              <span style="color: red">
                *** หากต้องการแก้ไขยอดเรียกเก็บใหม่
                กรุณาลบใบแจ้งหนี้ที่เรียกเก็บไปแล้วก่อนทำการแก้ไข
              </span>
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="4" md="3" lg="2"> น้ำหนัก : </v-col>
            <v-col cols="8" md="2" lg="2">
              <v-text-field
                :value="update_data_pack.weight"
                @change="(value) => (update_data_pack.weight = value)"
                type="number"
                autocomplete="off"
                ref="update_weight"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="4" md="3" lg="2"> กว้าง : </v-col>
            <v-col cols="8" md="2" lg="2">
              <v-text-field
                :value="update_data_pack.cubic1"
                @change="(value) => (update_data_pack.cubic1 = value)"
                type="number"
                autocomplete="off"
                ref="update_cubic1"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="4" md="3" lg="2">ยาว : </v-col>
            <v-col cols="8" md="2" lg="2">
              <v-text-field
                :value="update_data_pack.cubic2"
                @change="(value) => (update_data_pack.cubic2 = value)"
                type="number"
                autocomplete="off"
                ref="update_cubic2"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="4" md="3" lg="2"> สูง : </v-col>
            <v-col cols="8" md="2" lg="2">
              <v-text-field
                :value="update_data_pack.cubic3"
                @change="(value) => (update_data_pack.cubic3 = value)"
                type="number"
                autocomplete="off"
                ref="update_cubic3"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="4" md="3" lg="2"> ชื่อกล่อง : </v-col>
            <v-col cols="8" md="2" lg="2">
              <v-text-field
                :value="update_data_pack.boxName"
                @change="(value) => (update_data_pack.boxName = value)"
                autocomplete="off"
                ref="update_boxName"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="4" md="3" lg="2"> ค่ากล่อง : </v-col>
            <v-col cols="8" md="2" lg="2">
              <v-text-field
                :value="update_data_pack.boxPrice"
                @change="(value) => (update_data_pack.boxPrice = value)"
                type="number"
                autocomplete="off"
                ref="update_boxPrice"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="4" md="3" lg="2"> ค่าแพ็ค : </v-col>
            <v-col cols="8" md="2" lg="2">
              <v-text-field
                :value="update_data_pack.packPrice"
                @change="(value) => (update_data_pack.packPrice = value)"
                type="number"
                autocomplete="off"
                ref="update_packPrice"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="4" md="3" lg="2"> ค่าขนส่ง : </v-col>
            <v-col cols="8" md="2" lg="2">
              <v-text-field
                :value="update_data_pack.deliveryPrice"
                @change="(value) => (update_data_pack.deliveryPrice = value)"
                type="number"
                autocomplete="off"
                ref="update_deliveryPrice"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="4" md="3" lg="2"> % COD : </v-col>
            <v-col cols="8" md="2" lg="2">
              <v-text-field
                :value="update_data_pack.codPercent"
                @change="(value) => (update_data_pack.codPercent = value)"
                type="number"
                autocomplete="off"
                ref="update_codPercent"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="4" md="3" lg="2"> ค่าบริการ COD : </v-col>
            <v-col cols="8" md="9" lg="10">
              <span v-if="update_data_pack.codCharged != null"
                >{{ update_data_pack.codCharged }} บาท
              </span>
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="4" md="3" lg="2"> พื้นที่ห่างไกล : </v-col>
            <v-col cols="8" md="2" lg="2">
              <v-text-field
                :value="update_data_pack.remotePrice"
                @change="(value) => (update_data_pack.remotePrice = value)"
                type="number"
                autocomplete="off"
                ref="update_remotePrice"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="4" md="3" lg="2"> ส่วนลด : </v-col>
            <v-col cols="8" md="2" lg="2">
              <v-text-field
                :value="update_data_pack.discount"
                @change="(value) => (update_data_pack.discount = value)"
                type="number"
                autocomplete="off"
                ref="update_discount"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="4" md="3" lg="2"> ต้นทุนค่ากล่อง : </v-col>
            <v-col cols="8" md="2" lg="2">
              <v-text-field
                :value="update_data_pack.branchBoxCost"
                @change="(value) => (update_data_pack.branchBoxCost = value)"
                type="number"
                autocomplete="off"
                ref="update_branchBoxCost"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="4" md="3" lg="2"> ต้นทุนค่าขนส่ง : </v-col>
            <v-col cols="8" md="2" lg="2">
              <v-text-field
                :value="update_data_pack.branchDeliveryCost"
                @change="(value) => (update_data_pack.branchDeliveryCost = value)"
                type="number"
                autocomplete="off"
                ref="update_branchDeliveryCost"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pb-3">
            <v-col cols="4" md="3" lg="2"> ต้นทุนค่าบริการ COD : </v-col>
            <v-col cols="8" md="2" lg="2">
              <v-text-field
                :value="update_data_pack.branchCODChargedCost"
                @change="(value) => (update_data_pack.branchCODChargedCost = value)"
                type="number"
                autocomplete="off"
                ref="update_branchCODChargedCost"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row class="pb-3">
            <v-col align="right">
              <v-btn color="primary" @click="update_data_pack_function()">บันทึก</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Loading from "@/website/components/Loading";
import * as easings from "vuetify/es5/services/goto/easing-patterns";
import {
  customerWebsite,
  generalService_dotnet,
  orderService_dotnet,
  shopService_dotnet,
  stockService_dotnet,
  logisticService_dotnet,
  ecomService_dotnet,
} from "@/website/global";
import {
  format_number,
  GenPA,
  format_price,
  isNumberWNoDot,
  formatMoney,
  Trim_value,
  Trim_rules,
  formatDatetime,
} from "@/website/global_function";
export default {
  components: {
    Loading,
  },

  data: () => ({
    header_token: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("authorization_token"),
    },
    IsHasSerialNo: false,
    platformReferenceSourceID: [3, 4, 5, 11],
    page_loading: true,
    loading: false,
    orderItemList: [],
    orderItemTempList: [],
    orderIteminvoiceList: [],
    orderMasterPackageList: [],

    // แสดงภาพ
    ShowPhoto: false,
    ShowPhotoLink: "",

    // คลัง
    Warehouse_List: [],
    Warehouse: {},

    order_detail_token: null,
    IsChangeStatusStage: true,
    IsApproveStage: true,
    IsDetailStage: true,

    // แก้ไข จำนวน ราคา เหมาๆ
    dialog_edit_item_mhao: false,
    edit_item_mhao_id: null,
    edit_item_mhao_quantity: null,
    edit_item_mhao_unit_price: null,

    canChangeStatusOrder: null,
    can_edit_warehouse: false,
    can_edit_status: false,

    // add slip
    dialog_add_slip: false,

    // edit product
    dialog_edit_stock: false,
    isFullscreen: false,

    StockList: [],
    searchStockModel: null,
    hideNoDataSearchStock: true,
    searchInputStock: null,
    productList: [],
    totalVat: 0,
    textField_deliveryCost: null,
    select_discountType: "฿",
    textField_discount: null,
    totalDiscount: 0,
    totalPrice: 0,
    select_vatType: 1,
    vat_percent: 7,
    textarea_remarkProduct: null,

    // detail
    detail_order: null,
    // shipping_status: null,
    detail_order_product: null,
    detail_order_box: null,

    detailOrder: [],
    detail_CreatedateTime: "",

    detailOrderProduct: [],
    detailOrderProduct_pack: [],
    detailOrderProduct_tmp: [],
    detailOrderBox: [],
    detailOrderPayment: [],
    detail_ID: "",
    detail_statusId: "",
    detail_parcelStatusID: null,
    detail_parcelStatusName: null,
    detail_statusName: "",
    detail_ShopName: "",
    detail_ShopPhone: "",
    detail_ShopAddress: "",
    detail_ShipName: "",
    detail_ShipPhone: "",
    detail_line: "",
    detail_fackbook: "",
    detail_email: "",
    detail_edit_line: null,
    detail_edit_fackbook: null,
    detail_edit_email: null,

    detail_OrderDiscount: 0,
    detail_VATAmount: 0,
    detail_VATType: 1,

    detail_ShippingAddress: "",
    detail_BankName: "",
    detail_AccountNumber: "",
    detail_AccountName: "",
    detail_Remark: "",
    detail_Weight: "",
    detail_boxName: "",
    detail_Widht: "",
    detail_Height: "",
    detail_Long: "",
    detail_BoxPrice: "",
    detail_CODPercent: "",
    detail_CODCharged: "",
    detail_branchBoxCost: "",
    detail_branchCODChargedCost: "",
    detail_branchDeliveryCost: "",
    detail_Discount: "",
    detail_RemotePrice: "",
    detail_DeliveryPrice: "",
    detail_PackPrice: "",
    detail_TrackingNo: "",
    detail_expressCompanyLogo: null,
    detail_ChannelName: "",
    detail_StaffName: "",
    detail_totalPrice: "",
    detail_DeliveryCost: "",
    detail_Token: "",
    detail_IsCOD: "",
    detail_CustomerType: "",
    get_token: "",
    detail_CODAmount: "",
    detail_SellerVoucher: "",
    detail_PlatformVoucher: "",
    detail_SellerDeliveryDiscount: "",
    detail_orderTotalAmount: "",
    shipping_status: [],
    detail_sum_unintPrice: "",
    detail_sum_unintPrice_and_DeliveryCost: "",
    detail_RealTransferDatetime: "",
    detail_ExpressCompanyID: "",
    detail_SalemanName: null,
    detail_ShopBankAccountID: null,
    data_pic: [],
    detail_TempSenderAddress: null,
    detail_InvoiceGroupID: null,

    // transfer
    detail_CustomerTransferBankID: "",
    detail_CustomerTransferAmount: "",
    detail_CustomerTransferPhotoLink: "",
    detail_CustomerTransferCreatedDatetime: "",
    detail_CustomerTransferInformDatetime: "",

    detail_CustomerTransferBankName: "",
    detail_CustomerTransferAccountNumber: "",
    detail_CustomerTransferAccountName: "",
    detail_IsPreOrder: null,

    detail_ShippingAddressID: null,

    marketplace_status: null,

    // shopee
    detailOrderProductPlatform: [],
    detail_referenceSourceID: null,
    ReferenceNumber: null,
    // TempSenderAddress: null,
    TempAddress: null,
    ChannelSourceLogoPic: null,

    detail_shiping_staus_update_select: null,

    // changStatus
    change_items_status: [
      { text: "เปิดออร์เดอร์", value: 10 },
      { text: "รอตรวจยอด", value: 20 },
      { text: "รอแพ็ค", value: 30 },
      { text: "กำลังแพ็ค", value: 40 },
      { text: "แพ็คเรียบร้อย", value: 50 },
      { text: "จัดส่งแล้ว", value: 70 },
      { text: "ยกเลิก", value: 90 },
    ],

    mhao_change_items_status: [
      { text: "เปิดออร์เดอร์", value: 10 },
      { text: "รอตรวจยอด", value: 20 },
      { text: "รอแพ็ค", value: 30 },
      { text: "ยกเลิก", value: 90 },
    ],

    order_status_history_list: [],

    change_items_status_select: null,

    dialog_photo: false,
    dialog_photo_link: null,

    // แก้ไขข้อมูล remark
    edit_detail_remark: null,
    can_edit_remark: false,
    can_edit_product: false,

    // แก้ไขที่อยู่
    can_edit_shipaddress: false,
    // postcode_mapping: PostcodeMapping,
    postcode_mapping: [],
    ShipAddressManage: {
      ID: null,
      Name: null,
      Phone: null,
      TextAddress: null,
      Address: null,
      PostcodeMappingID: null,
      ShopCustomerID: null,
    },

    // แก้ไข cod ธนาคาร
    can_edit_data_cod: false,
    edit_cod_amount: null,
    edit_cod_bank: null,
    cod_bank_data: [],
    cod_list_select: null,
    cod_list: [
      { ID: 0, text: "ธรรมดา" },
      { ID: 1, text: "COD" },
    ],

    // แก้ไข express
    can_edit_data_express: false,
    edit_express: null,
    edit_express_list: [],

    input_transferFileUpload: null,
    input_transferAmount: null,
    menu_transfer_date: null,
    select_transferDate: null,
    select_transferHour: null,
    select_transferMinute: null,
    transferHourItems: [
      "00",
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
    ],
    transferMinuteItems: [
      "00",
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31",
      "32",
      "33",
      "34",
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
      "51",
      "52",
      "53",
      "54",
      "55",
      "56",
      "57",
      "58",
      "59",
    ],

    data_list_payment: [],

    // windowSize
    size: "lg",
    window: {
      width: 0,
      height: 0,
    },

    // paginations
    page: 1,
    pageTemp: 1,
    pageLength: 1,
    pageTotalVisible: 9,
    limits: 15,
    offsets: 0,

    // popup
    Popupsearch: null,
    searchStockModelPopup: null,
    addOrderPopup: {
      MaxWidth: "50%",
      MaxHeight: 90,
      Dialog: false,
      headers: [
        {
          text: "เลือก",
          align: "center",
          sortable: false,
          value: "selectProduct",
          width: "50px",
        },
        { text: "No", align: "center", sortable: false, value: "No", width: "60px" },
        {
          text: "รูป",
          align: "center",
          sortable: false,
          value: "photoLink",
          width: "60px",
        },
        { text: "ชื่อ", align: "left", sortable: false, value: "name" },
        { text: "ลักษณะ", align: "center", sortable: false, value: "prop1" },
        {
          text: "รหัสสินค้า",
          align: "center",
          sortable: false,
          value: "productCode",
          width: "90px",
        },
        { text: "SKU", align: "center", sortable: false, value: "sku" },
        { text: "Barcode", align: "center", sortable: false, value: "barcode" },
        {
          text: "ราคา",
          align: "right",
          sortable: false,
          value: "unitPrice",
          width: "90px",
        },
        {
          text: "คงเหลือ",
          align: "center",
          sortable: false,
          value: "quantityAvailable",
          width: "90px",
        },
      ],
      Select_Product: [],
      Data: [],
      ProductID_List: [],
      loading: false,
    },

    // แจ้งสถานะ เหมาๆ
    mhaoDialog: false,
    row_id: null,
    mh_line_item_id: null,
    mh_selectStatus: null,
    mh_itemsStatus: [
      { text: "กรุณาเลือกสถานะ", value: null },
      { text: "สั่งร้านจีนเรียบร้อย", value: 2 },
      { text: "ร้านค้าจีนส่งออก", value: 3 },
      { text: "สินค้าเข้าโกดังจีน", value: 4 },
    ],

    // เปิด เลือกวันที่
    startDate: null,
    menuStartDate: false,
    checkStart: true,

    time: null,
    menu2: false,
    modal2: false,

    // endDate: null,
    // menuEndDate: false,
    // checkEnd: true,
    // ปิด เลือกวันที่
    header_edit_item_show: [],
    header_edit_item: [
      { text: "#", value: "Index", align: "center", sortable: false, width: 10 },
      { text: "รูป", value: "photoLink", align: "center", sortable: false, width: 10 },
      { text: "ชื่อสินค้า", value: "productName", align: "center", sortable: false },
      { text: "ราคาขาย", value: "unitPrice", align: "center", sortable: false },
      { text: "จำนวน", value: "quantity", align: "center", sortable: false },
      { text: "ส่วนลด%", value: "discountPercent", align: "center", sortable: false },
      { text: "ส่วนลด", value: "discount", align: "center", sortable: false },
      { text: "ราคารวม", value: "itemPrice", align: "right", sortable: false },
      { text: "", value: "Delete", align: "center", sortable: false, width: 10 },
    ],

    update_data_pack: {
      weight: null,
      cubic1: null,
      cubic2: null,
      cubic3: null,
      boxPrice: null,
      packPrice: null,
      deliveryPrice: null,
      codPercent: null,
      codCharged: null,
      remotePrice: null,
      discount: null,
      branchBoxCost: null,
      branchCODChargedCost: null,
      branchDeliveryCost: null,
    },
    edit_box_dialog: false,
    ProductPrice: 0,

    // edit channel list
    editSaleman: false,
    channelList: [],
    channel: {},
    staffShopList: [],
    staffShop: {},

    IsHaveProductSet: false,

    // เรียกข้อมูล order

    PackImage: [],
    statusExpress: null,
  }),

  computed: {
    ////////////////////////////////////////////////////////////////

    computed_transfer_date() {
      if (this.select_transferDate == null) {
        return null;
      } else {
        return this.formatDate(this.select_transferDate);
      }
    },
  },

  watch: {
    cod_list_select: function (val) {
      if (val == 0) {
        this.edit_cod_bank = this.detail_ShopBankAccountID;
        this.input_transferFileUpload = null;
        this.input_transferAmount = null;
        this.select_transferDate = null;
        this.select_transferHour = null;
        this.select_transferMinute = null;
        this.data_list_payment = [];
      }
    },
    startDate: async function (newVal) {
      if (newVal) {
        this.$refs.menuStartDate.save(this.startDate);
      }
    },
  },

  destroyed() {
    // windowSize
    window.removeEventListener("resize", this.handleResize);
  },

  async created() {
    this.shop_id = localStorage.getItem("shop_id");
    this.canChangeStatusOrder = JSON.parse(localStorage.getItem("permission"))[
      "ChangeStatusOrder"
    ];
    this.CanApprove = JSON.parse(localStorage.getItem("permission"))["ApproveOrder"];

    this.header_edit_item_show = this.header_edit_item;

    await this.get_postcode_mapping();
    // windowSize
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (this.window.width < 960) {
      this.addOrderPopup.MaxWidth = "100%";
    } else {
      this.addOrderPopup.MaxWidth = "70%";
    }
    if (this.size == "xs" || this.size == "sm") {
      this.limits = 25;
      this.addOrderPopup.MaxHeight = (90 * this.window.height) / 100;
      this.window.widthname = this.window.width - 35 - 90 - 70 - 30;
    } else {
      this.addOrderPopup.MaxHeight =
        (this.addOrderPopup.MaxHeight * this.window.height) / 100;
      // cal limits
      this.limits = parseInt((this.addOrderPopup.MaxHeight - 57 - 52 - 56 - 48) / 48);
    }

    this.order_detail_token = this.$route.query.token;
    this.detail_ID = this.$route.query.id;
    if (typeof this.order_detail_token === "undefined") {
      this.order_detail_token = null;
    }
    if (typeof this.detail_ID === "undefined") {
      this.detail_ID = null;
    }

    await this.ShowDetailOrder_tmp(this.detail_ID);
    document.title = "ออเดอร์ : " + this.GenPA(this.detail_ID);

    // get staff channel list
    this.getStaffShop();

    // get shop express
    if (this.detail_statusId <= 30) {
      this.get_shop_express_data(this.shop_id);
    } else {
      this.edit_express_list = [{ text: "รับสินค้าด้วยตัวเอง", value: 0 }];
    }

    // เรียกข้อมูล order

    this.show_reject = false;
    this.page_loading = false;
  },

  methods: {
    async getStaffShop() {
      let response = await axios.post(
        shopService_dotnet + "Shop/get-shop-staff",
        {
          shopID: localStorage.getItem("shop_id"),
        },
        { headers: this.header_token }
      );
      if (response.status == 200) {
        this.staffShopList = response.data;
        this.staffShop = this.staffShopList.filter(
          (x) => x.id == this.detail_order.salemanID
        )[0];
      }
    },

    async SummitSaleman() {
      this.loading = true;
      let response = await axios.post(
        orderService_dotnet + "Order/update-sale-channel",
        {
          Id: this.detail_order.id,
          salemanID: this.staffShop.id,
          referenceSourceID: this.channel.id,
        },
        { headers: this.header_token }
      );
      this.loading = false;
      if (response.status == 200) {
        Swal.fire({
          toast: true,
          timer: 1500,
          showConfirmButton: false,
          position: "top",
          type: "success",
          title: "Success",
        });
        this.detail_order.salemanID = this.staffShop.id;
        this.detail_order.salemanName = this.staffShop.name;
        this.detail_order.referenceSourceID = this.channel.id;
        this.detail_order.channelName = this.channel.channelName;
        this.detail_order.channelLogo = this.channel.logoPic;
        this.editSaleman = false;
      } else {
        this.loading = false;
        Swal.fire({
          position: "top",
          type: "error",
          text: "ไม่สามารถทำรายการได้",
        });
      }
    },

    format_number,
    GenPA,
    format_price,
    isNumberWNoDot,
    formatMoney,
    Trim_value,
    Trim_rules,
    formatDatetime,

    can_edit_cod() {
      if (this.detail_IsCOD == true) {
        this.cod_list_select = 1;
      } else {
        this.cod_list_select = 0;
      }
    },

    allowedDates: (val) => val <= new Date().toISOString().substr(0, 10),

    formatDate(date) {
      date = [date];
      if (date.length == 0) return null;

      if (date.length == 1) {
        const [year, month, day] = date[0].split("-");
        return `${day}-${month}-${year}`;
      } else {
        const [year_from, month_from, day_from] = date[0].split("-");
        const [year_to, month_to, day_to] = date[1].split("-");
        if (date[0] < date[1]) {
          return `${day_from}-${month_from}-${year_from} ~ ${day_to}-${month_to}-${year_to}`;
        } else {
          return `${day_to}-${month_to}-${year_to} ~ ${day_from}-${month_from}-${year_from}`;
        }
      }
    },

    // windowSize
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      if (this.window.width < 600) {
        this.size = "xs";
      } else if (this.window.width >= 600 && this.window.width < 960) {
        this.size = "sm";
      } else if (this.window.width >= 960 && this.window.width < 1264) {
        this.size = "md";
      } else if (this.window.width >= 1264 && this.window.width < 1904) {
        this.size = "lg";
      } else if (this.window.width >= 1904) {
        this.size = "xl";
      }
    },
    OpenPhoto(Photo) {
      this.ShowPhotoLink = Photo;
      this.ShowPhoto = true;
    },
    Opentap(PhotoLink) {
      window.open(PhotoLink);
    },

    async get_shop_express_data(shop_id) {
      let response1 = await axios.post(
        orderService_dotnet + "Order/detail-order-get-express-for-edit",
        { shopID: shop_id },
        { headers: this.header_token }
      );
      var expres_list_tmp = [];
      var param_tmp;
      if (response1.data.length > 0 && response1.data != null) {
        param_tmp = { text: "รับสินค้าด้วยตัวเอง", value: 0 };
        expres_list_tmp.push(param_tmp);
        for (var i = 0; i < response1.data.length; i++) {
          param_tmp = {
            text: response1.data[i]["name"],
            value: response1.data[i]["id"],
          };
          expres_list_tmp.push(param_tmp);
        }
      } else {
        param_tmp = { text: "รับสินค้าด้วยตัวเอง", value: 0 };
        expres_list_tmp.push(param_tmp);
      }
      this.edit_express_list = expres_list_tmp;
    },

    async update_shop_express_id() {
      this.loading = true;
      let response = await axios.post(
        orderService_dotnet + "Order/detail-order-edit-data-order-master",
        { id: this.detail_ID, expressCompanyID: this.edit_express },
        { headers: this.header_token }
      );
      if (response.data.status) {
        this.can_edit_data_express = false;

        this.ShowDetailOrder_tmp(this.detail_ID);
      }
      this.loading = false;
    },
    OpenEditBoxDialog(item) {
      this.update_data_pack = {
        id: item.id,
        packageNo: item.packageNo,
        boxName: item.boxName,
        weight: item.weight,
        cubic1: item.cubic1,
        cubic2: item.cubic2,
        cubic3: item.cubic3,
        boxPrice: item.boxPrice,
        packPrice: item.packPrice,
        remotePrice: item.remotePrice,
        deliveryPrice: item.deliveryPrice,
        codPercent: item.codPercent,
        codCharged: item.codCharged,
        discount: item.discount,
        branchBoxCost: item.branchBoxCost,
        branchDeliveryCost: item.branchDeliveryCost,
        BranchCODChargedCost: item.BranchCODChargedCost,
      };
      this.edit_box_dialog = true;
    },
    remove_data_product(data_product, StockID) {
      // ลบข้อมูลใน this.detailOrderProduct_tmp
      for (var n = 0; n < this.detailOrderProduct_tmp.length; n++) {
        if (StockID == this.detailOrderProduct_tmp[n]["id"]) {
          this.detailOrderProduct_tmp.splice(n, 1);
          break;
        }

        if (this.addOrderPopup.ProductID_List.length > 0) {
          for (var i = 0; i < this.addOrderPopup.ProductID_List.length; i++) {
            this.addOrderPopup.ProductID_List;
            if (this.addOrderPopup.ProductID_List[i] == StockID) {
              this.addOrderPopup.ProductID_List.splice(i, 1);
            }
          }
        }
      }

      // ลบข้อมูลใน this.addOrderPopup.ProductID_List
      // this.addOrderPopup.ProductID_List.splice(this.addOrderPopup.ProductID_List.indexOf(StockID), 1)

      for (var f = 0; f < this.detailOrderProduct_tmp.length; f++) {
        if (
          this.detailOrderProduct_tmp[f]["stockShopId"] == data_product["stockShopId"]
        ) {
          this.detailOrderProduct_tmp.splice(f, 1);
          break;
        }
      }

      this.calculateTotalStockPrice();
    },

    async update_data_product() {
      this.loading = true;
      let self = this;
      self.orderItem = [];

      var string_alert = "";
      var orderItem_dict = {};
      for (var i = 0; i < self.detailOrderProduct_tmp.length; i++) {
        if (
          self.detailOrderProduct_tmp[i].isProductSet == true ||
          self.detailOrderProduct_tmp[i].isProductSet == 1
        ) {
          var data_item_product_set = await self.check_stock_item_product_set(
            self.detailOrderProduct_tmp[i].productMasterId,
            self.detailOrderProduct_tmp[i].quantityInput
          );
          var StatusFalseList = [];
          var IsTrue = true;
          for (var ii = 0; ii < data_item_product_set.length; ii++) {
            if (data_item_product_set[ii].status == false) {
              IsTrue = false;
              StatusFalseList.push(data_item_product_set[ii]);
            }
          }
          if (IsTrue == false) {
            var stringAlert = "";
            for (var i2 = 0; i2 < StatusFalseList.length; i2++) {
              if (i2 > 0) {
                stringAlert =
                  stringAlert + " ," + StatusFalseList[i2]["name"] + " " + "คงเหลือไม่พอ";
              } else {
                stringAlert =
                  stringAlert + StatusFalseList[i2]["name"] + " " + "คงเหลือไม่พอ";
              }
            }
            self.$swal({
              position: "top",
              type: "info",
              title: "จำนวนคงเหลือไม่พอ !!",
              text: stringAlert,
            });
            self.loading = false;
            return;
          } else {
            orderItem_dict = {
              id: 0,
              orderMasterId: 0,
              orderItemTempId: null,
              stockShopId: 0, //0 =  คือ product set   //self.detailOrderProduct_tmp[i].id,
              quantity: self.detailOrderProduct_tmp[i].quantity,
              unitPrice: self.detailOrderProduct_tmp[i].unitPrice,
              discountPercent: self.detailOrderProduct_tmp[i].discountPercent,
              discount: self.detailOrderProduct_tmp[i].discount,
              isLock: true,
              productMasterID: self.detailOrderProduct_tmp[i].productMasterId,
              productMasterItemID: self.detailOrderProduct_tmp[i].productMasterItemId,
              isProductSet: true,
            };
            self.orderItem.push(orderItem_dict);
          }
        } else {
          if (self.IsAllowAddOrderWhenStockShopNotAvailable == false) {
            var datares = await self.searchStock_check_quantity(
              self.detailOrderProduct_tmp[i].stockShopId
            );
            if (self.detailOrderProduct_tmp[i].quantityInput > datares) {
              string_alert +=
                self.detailOrderProduct_tmp[i].stockFullName +
                "จำนวนคงเหลือ = " +
                datares.toString() +
                "  ,";
            }
          }

          orderItem_dict = {
            id: 0,
            orderMasterId: 0,
            stockShopId: self.detailOrderProduct_tmp[i].stockShopId,
            quantity: self.detailOrderProduct_tmp[i].quantity,
            unitPrice: self.detailOrderProduct_tmp[i].unitPrice,
            discountPercent: self.detailOrderProduct_tmp[i].discountPercent,
            discount: self.detailOrderProduct_tmp[i].discount,
            isLock: true,
            productMasterID: self.detailOrderProduct_tmp[i].productMasterId,
            productMasterItemID: self.detailOrderProduct_tmp[i].productMasterItemId,
            isProductSet: false,
          };
          self.orderItem.push(orderItem_dict);
        }
      }

      if (string_alert != "") {
        self.$swal({
          position: "top",
          type: "error",
          title: "จำนวนสินค้าไม่พอ !!",
          text: string_alert,
        });
        self.loading = false;
        return;
      }

      var orderMasterParam_tmp = {
        orderMasterID: this.detail_ID,
        vatPercent: this.vat_percent,
        deliveryCost: this.textField_deliveryCost,
        orderDiscountPercent:
          this.select_discountType == "฿" ? null : this.textField_discount,
        orderDiscount:
          this.select_discountType == "%" ? this.totalDiscount : this.textField_discount,
        vatType: this.select_vatType,
        vatAmount: this.totalVat,
        totalPrice: this.totalPrice,
      };

      let response = await axios.post(
        orderService_dotnet + "Order/detail-order-edit-items",
        {
          orderItemInvoice: self.orderItem,
          orderMasterParam: orderMasterParam_tmp,
        },
        { headers: this.header_token }
      );
      if (response.data.status) {
        this.can_edit_product = false;
        this.ShowDetailOrder_tmp(this.detail_ID);
      }

      self.loading = false;
    },

    discountPercent(item) {
      if (item.discountPercent != "") {
        var discount =
          (Number(item.unitPrice) *
            Number(item.quantity) *
            Number(item.discountPercent)) /
          100;
        item.discount = discount.toFixed(2);
      } else {
        item.discount = null;
      }
    },

    async unitPriceInput(item) {
      if (item.unitPrice == "") {
        await this.$swal({
          position: "top",
          type: "info",
          text: "กรุณาใส่ราคาสินค้า",
        });
        item.VueComponentFocus.unitPrice();
      } else {
        item.unitPrice = Number(item.unitPrice).toFixed(2);
        this.calculateDiscountPercent(item);
        this.calculateTotalStockPrice();
      }
    },

    async quantityInput(item) {
      var quantity = item.quantity;
      if (quantity == "" || quantity == null) {
        await this.$swal({
          position: "top",
          type: "info",
          text: "กรุณาใส่จำนวนสินค้า",
        });
        item.VueComponentFocus.quantityInput();
      } else {
        this.calculateDiscountPercent(item);
        this.calculateTotalStockPrice();
      }
    },

    calculateDiscountPercent(item) {
      var discountPercent = Number(item.discountPercent);
      if (!Number.isNaN(discountPercent) && discountPercent != 0) {
        var price = Number(item.unitPrice);
        var quantity = Number(item.quantity);
        item.discount = Number((price * quantity * discountPercent) / 100).toFixed(2);
      } else {
        item.discountPercent = null;
        item.discount = null;
      }
    },

    calculateTotalStockPrice() {
      this.totalPrice = 0;
      var price = 0;
      var quantity = 0;
      var discountPercent = 0;
      var discount = 0;
      for (var i = 0; i < this.detailOrderProduct_tmp.length; i++) {
        price = Number(this.detailOrderProduct_tmp[i].unitPrice);
        quantity = Number(this.detailOrderProduct_tmp[i].quantity);
        if (quantity > 0 && price >= 0) {
          discount = Number.isNaN(Number(this.detailOrderProduct_tmp[i].discount))
            ? 0
            : Number(this.detailOrderProduct_tmp[i].discount);
          // this.productList[i]['itemPrice'] = price * quantity - discount
          this.detailOrderProduct_tmp[i]["itemPrice"] = price * quantity - discount;
          this.totalPrice += price * quantity - discount;
        }
      }
      this.ProductPrice = this.totalPrice;
      this.calculateDiscountOrder();
      this.calculateVAT();
    },

    calculateVAT() {
      this.totalVat = 0;
      // Set COD Amount
      this.vat_percent = 0;
      this.textfield_codAmount = (
        this.totalPrice +
        Number(this.textField_deliveryCost) -
        this.totalDiscount
      ).toFixed(2);
      if (this.select_vatType == 2) {
        this.vat_percent = 7;
        this.totalVat =
          ((this.totalPrice + Number(this.textField_deliveryCost) - this.totalDiscount) *
            this.vat_percent) /
          107;
      } else if (this.select_vatType == 3) {
        this.vat_percent = 7;
        this.totalVat =
          ((this.totalPrice + Number(this.textField_deliveryCost) - this.totalDiscount) *
            this.vat_percent) /
          100;
        // Set COD Amount
        this.textfield_codAmount = (
          Number(this.textfield_codAmount) + this.totalVat
        ).toFixed(2);
      }
    },

    calculateDiscountOrder() {
      if (this.select_discountType == "฿") {
        this.totalDiscount = Number(this.textField_discount);
      } else {
        this.totalDiscount =
          ((this.totalPrice + Number(this.textField_deliveryCost)) *
            Number(this.textField_discount)) /
          100;
      }
      this.calculateVAT();
    },

    black_to_order_page() {
      window.close();
    },

    set_format_date_time(val) {
      if (val != null) {
        var date_tmp = val.substring(0, 10);
        const date_split = date_tmp.split("-");
        var time_tmp = val.substring(11, 19);
        var set_dattime =
          date_split[2] + "-" + date_split[1] + "-" + date_split[0] + " " + time_tmp;
        return set_dattime;
      } else {
        return "";
      }
    },

    bank_id_to_text(id) {
      for (var e = 0; e < this.cod_bank_data.length; e++) {
        if (this.cod_bank_data[e]["ID"] == id) {
          return this.cod_bank_data[e]["text"];
        }
      }
    },

    async get_orderid_by_token(token) {
      let response = await axios.post(
        orderService_dotnet + "Order/get-order-master",
        { token: token },
        { headers: this.header_token }
      );
      this.order_token = response.data.id;
      return this.order_token;
    },

    async backStatusOrderToOpen() {
      this.loading = true;
      let response = await axios.post(
        orderService_dotnet + "Order/detail-order-back-status-order-to-open",
        {
          orderMasterID: this.detail_ID,
          staffShopID: localStorage.getItem("staff_id"),
        },
        { headers: this.header_token }
      );

      if (response.data.status) {
        this.ShowDetailOrder_tmp(this.detail_ID);
      }
      this.loading = false;
    },

    async get_shopAddress_loadpage(val) {
      let res1 = await axios.post(
        shopService_dotnet + "ShopAddress/get-shop-address",
        { id: val },
        { headers: this.header_token }
      );
      this.detail_ShopPhone = res1.data.phone;
      this.detail_ShopAddress =
        res1.data.address +
        " " +
        res1.data.subDistrict +
        " " +
        res1.data.district +
        " " +
        res1.data.province +
        " " +
        res1.data.postcode;

      if (this.detail_TempSenderAddress == null) {
        this.detail_TempSenderAddress =
          res1.data.name + " " + res1.data.phone + " " + this.detail_ShopAddress;
      }
    },

    async get_shippingAddress_loadpage(val) {
      // getshippingAddress
      let res2 = await axios.post(
        shopService_dotnet + "Shop/get-shipping-address-list",
        { id: val, isAscending: true },
        { headers: this.header_token }
      );
      this.detail_line = res2.data[0].customerLine;
      this.detail_fackbook = res2.data[0].customerFacebook;
      this.detail_email = res2.data[0].customerEmail;

      this.detail_edit_line = res2.data[0].customerLine;
      this.detail_edit_fackbook = res2.data[0].customerFacebook;
      this.detail_edit_email = res2.data[0].customerEmail;

      this.detail_ShippingAddress =
        res2.data[0].address +
        " " +
        res2.data[0].subDistrict +
        " " +
        res2.data[0].district +
        " " +
        res2.data[0].province +
        " " +
        res2.data[0].postcode;

      if (this.detail_TempAddress == null) {
        this.detail_TempAddress =
          res2.data[0].name +
          " " +
          res2.data[0].phone +
          " " +
          this.detail_ShippingAddress;
      }
    },

    async get_order_shop_bank_by_id(val) {
      // getshippingAddress
      let res = await axios.post(
        orderService_dotnet + "Order/get-order-shop-bank-by_id",
        { shopBankID: val },
        { headers: this.header_token }
      );
      this.detail_BankName = res.data[0].bankNameTH;
      this.detail_AccountNumber = res.data[0].accountNumber;
      this.detail_AccountName = res.data[0].accountName;
    },

    async ShowDetailOrder_tmp(OrderID) {
      await axios
        .all([
          axios.post(
            orderService_dotnet + "Package/get-order-package-info",
            { orderID: OrderID },
            { headers: this.header_token }
          ),
          axios.post(
            orderService_dotnet + "Order/get-order-master",
            { orderID: OrderID,token:this.order_detail_token },
            { headers: this.header_token }
          ),
          axios.post(
            orderService_dotnet + "Order/get-order-satus-history",
            { orderID: OrderID },
            { headers: this.header_token }
          ),
          axios.post(
            orderService_dotnet + "Order/get-pack-image",
            { orderMasterID: OrderID },
            { headers: this.header_token }
          ),
          axios.get(
            logisticService_dotnet + "Logistic/get-track-status?OrderMasterID=" + OrderID,
            { headers: this.header_token }
          ),
        ])
        .then(
          axios.spread(
            (
              res_PackageList,
              res_OrderMasterDict,
              res_StatusHistory,
              res_PackImage,
              res_TrackStatus
            ) => {
              this.order_detail_token=null;
              console.log(res_OrderMasterDict.data);
              
              if(res_OrderMasterDict.data==null||res_OrderMasterDict.data=='' )
                window.close();
              var detail_order = res_OrderMasterDict.data;
              this.detail_order = res_OrderMasterDict.data;
              this.PackImage = res_PackImage.data;
              this.orderItemList = res_PackageList.data.orderItemList;
              this.orderItemTempList = res_PackageList.data.orderItemTempList;
              this.orderIteminvoiceList = res_PackageList.data.orderItemInvoiceList;
              this.orderMasterPackageList = res_PackageList.data.packageList;
           
              
              this.orderMasterPackageList.forEach((element) => {
                element.itemList = this.orderItemList.filter(
                  (packageno) => packageno.packageNo == element.packageNo
                );
                var trackStatusTemp = res_TrackStatus.data.packages.filter(
                  (packageno) => packageno.packageNo == element.packageNo
                );
                if (trackStatusTemp.length > 0) {
                  element.trackStatus = trackStatusTemp[0];
                }
              });

              this.orderItemList.forEach((item) => {
                if (item.serialNumber != null) {
                  this.IsHasSerialNo = true;
                }
              });

              // getshopAddress
              if (detail_order.shopAddressID) {
                this.get_shopAddress_loadpage(detail_order.shopAddressID);
              }
              // getshippingAddress
              if (detail_order.shippingAddressID) {
                this.get_shippingAddress_loadpage(detail_order.shippingAddressID);
              }

              if (detail_order.shopBankAccountID != null) {
                this.get_order_shop_bank_by_id(detail_order.shopBankAccountID);
              } else {
                this.detail_BankName = null;
                this.detail_AccountNumber = null;
                this.detail_AccountName = null;
              }
              this.update_data_pack = {
                weight: null,
                cubic1: null,
                cubic2: null,
                cubic3: null,
                boxPrice: null,
                packPrice: null,
                deliveryPrice: null,
                codPercent: null,
                codCharged: null,
                remotePrice: null,
                discount: null,
              };

              // set data update pack
              this.update_data_pack.weight = detail_order["weight"];
              this.update_data_pack.cubic1 = detail_order["cubic1"];
              this.update_data_pack.cubic2 = detail_order["cubic2"];
              this.update_data_pack.cubic3 = detail_order["cubic3"];
              this.update_data_pack.boxPrice = detail_order["boxPrice"];
              this.update_data_pack.packPrice = detail_order["packPrice"];
              this.update_data_pack.deliveryPrice = detail_order["deliveryPrice"];
              this.update_data_pack.codPercent = detail_order["codPercent"];
              this.update_data_pack.codCharged = detail_order["codCharged"];
              this.update_data_pack.remotePrice = detail_order["remotePrice"];
              this.update_data_pack.discount = detail_order["discount"];
              this.update_data_pack.branchBoxCost = detail_order["branchBoxCost"];
              this.update_data_pack.branchCODChargedCost =
                detail_order["branchCODChargedCost"];
              this.update_data_pack.branchDeliveryCost =
                detail_order["branchDeliveryCost"];

              this.change_items_status_select = detail_order["shippingStatusID"];
              this.detail_ID = detail_order["id"];
              this.detail_statusId = detail_order["shippingStatusID"];
              this.detail_shiping_staus_update_select = detail_order["shippingStatusID"];

              this.detail_statusName = detail_order["shippingStatusName"];
              this.detail_ShopName = detail_order["senderName"];
              this.detail_ShipName = detail_order["receiverName"];
              this.detail_InvoiceGroupID = detail_order["invoiceGroupID"];

              this.detail_boxName = detail_order["boxName"];
              this.detail_branchBoxCost = detail_order["branchBoxCost"];
              this.detail_branchCODChargedCost = detail_order["branchCODChargedCost"];
              this.detail_branchDeliveryCost = detail_order["branchDeliveryCost"];

              this.detail_parcelStatusID = detail_order["parcelStatusID"];
              this.detail_parcelStatusName = detail_order["parcelStatusName"];
              this.detail_ShipPhone = detail_order["receiverPhone"];
              this.detail_Remark = detail_order["remark"];
              this.detail_Weight = detail_order["weight"];
              this.detail_Widht = detail_order["cubic1"];
              this.detail_Long = detail_order["cubic2"];
              this.detail_Height = detail_order["cubic3"];
              this.detail_BoxPrice = detail_order["boxPrice"];
              this.detail_CODPercent = detail_order["codPercent"];
              this.detail_CODCharged = detail_order["codCharged"];
              this.detail_Discount = detail_order["discount"];
              this.detail_RemotePrice = detail_order["remotePrice"];
              this.detail_DeliveryPrice = detail_order["deliveryPrice"];
              this.detail_PackPrice = detail_order["packPrice"];
              this.detail_IsPreOrder = detail_order["isPreOrder"];
              this.detail_totalPrice = parseFloat(
                detail_order["totalPrice"] == null ? 0 : detail_order["totalPrice"]
              );
              this.detail_DeliveryCost = parseFloat(
                detail_order["deliveryCost"] == null ? 0 : detail_order["deliveryCost"]
              );
              this.vat_percent = detail_order["vatPercent"];
              this.textField_deliveryCost = parseFloat(
                detail_order["deliveryCost"] == null ? 0 : detail_order["deliveryCost"]
              );
              this.select_vatType = detail_order["vatType"];

              if (detail_order["orderDiscountPercent"] == null) {
                this.select_discountType = "฿";
                this.textField_discount = detail_order["orderDiscount"];
              } else {
                this.select_discountType = "%";
                this.textField_discount = detail_order["orderDiscountPercent"];
              }

              this.detail_OrderDiscount = parseFloat(
                detail_order["orderDiscount"] == null ? 0 : detail_order["orderDiscount"]
              );
              this.detail_VATAmount = parseFloat(
                detail_order["vatAmount"] == null ? 0 : detail_order["vatAmount"]
              );
              this.detail_VATType = this.select_vatType;

              // set data tmp
              this.vat_percent_tmp = JSON.parse(JSON.stringify(this.vat_percent));
              this.textField_deliveryCost_tmp = JSON.parse(
                JSON.stringify(this.textField_deliveryCost)
              );
              this.select_discountType_tmp = JSON.parse(
                JSON.stringify(this.select_discountType)
              );
              this.textField_discount_tmp = JSON.parse(
                JSON.stringify(this.textField_discount)
              );
              this.select_vatType_tmp = JSON.parse(JSON.stringify(this.select_vatType));

              this.detail_TrackingNo = detail_order["trackingNo"];
              this.detail_expressCompanyLogo = detail_order["expressCompanyLogo"];
              this.get_token = detail_order["token"];
              this.detail_IsCOD = detail_order["isCOD"];
              this.detail_CustomerType = detail_order["customerType"];
              this.detail_CODAmount = detail_order["codAmount"];
              this.detail_RealTransferDatetime = detail_order["realTransferDatetime"];
              this.detail_Token = customerWebsite + "purchase-order=" + this.get_token;
              this.detail_ExpressCompanyID = detail_order["expressCompanyID"];
              this.edit_express = detail_order["expressCompanyID"];

              this.detail_StaffName = detail_order["createdByStaffShopName"];
              this.detail_SalemanName = detail_order["salemanName"];

              this.detail_ShopBankAccountID = detail_order["shopBankAccountID"];

              // data shopee
              this.detail_referenceSourceID = detail_order["referenceSourceID"];
              this.detail_ReferenceNumber = detail_order["referenceNumber"];
              this.detail_TempSenderAddress = detail_order["tempSenderAddress"];
              this.detail_TempAddress = detail_order["tempAddress"];
              this.detail_ChannelSourceLogoPic = detail_order["channelLogo"];

              if (
                this.detail_referenceSourceID == 3 ||
                this.detail_referenceSourceID == 4 ||
                this.detail_referenceSourceID == 11
              ) {
                this.get_status_order_marketplace(this.detail_ID);
              }

              // shipAddress
              if (detail_order["shippingAddressID"] != null) {
                this.detail_ShippingAddressID = detail_order["shippingAddressID"];
                this.getDataShippingAddressManageID(detail_order["shippingAddressID"]);
              }

              // get bank data
              this.get_bank_cod(detail_order);

              if (
                this.orderItemList.length != 0 ||
                this.orderIteminvoiceList.length != 0
              ) {
                // ถ้ามีสินค้าเป็น set ใช้ข้อมูลจาก order item invoice
                this.IsHaveProductSet = false;
                for (var ic = 0; ic < this.orderIteminvoiceList.length; ic++) {
                  if (this.orderIteminvoiceList[ic].isProductSet == true) {
                    this.IsHaveProductSet = true;
                    break;
                  }
                }

                if (this.orderIteminvoiceList.length > 0) {
                  // order set
                  this.detailOrderProduct = this.orderIteminvoiceList;
                } else {
                  // order item ปกติ
                  this.detailOrderProduct = this.orderItemList;
                }

                this.detail_sum_unintPrice = 0;
                this.detail_sum_unintPrice_and_DeliveryCost = 0;
                for (var i = 0; i < this.detailOrderProduct.length; i++) {
                  var item_quantity =
                    this.detailOrderProduct[i]["quantity"] == null
                      ? 0
                      : this.detailOrderProduct[i]["quantity"];
                  var item_unitPrice =
                    this.detailOrderProduct[i]["unitPrice"] == null
                      ? 0
                      : this.detailOrderProduct[i]["unitPrice"];
                  this.detail_sum_unintPrice +=
                    parseFloat(item_quantity) * parseFloat(item_unitPrice);
                }
                this.detail_sum_unintPrice_and_DeliveryCost =
                  parseFloat(this.detail_sum_unintPrice) +
                  parseFloat(this.detail_DeliveryCost);

                // add data StockList
                this.get_v_stock_shop_by_id();
              }

              // data_pic
              this.data_pic = res_OrderMasterDict.data.imagePack;

              // order status history
              this.order_status_history_list = res_StatusHistory.data;
            }
          )
        );
    },

    async detail_edit_remark(order_id) {
      if (this.edit_detail_remark == "") {
        this.edit_detail_remark = "-";
      }

      this.loading = true;
      let response = await axios.post(
        orderService_dotnet + "Order/detail-order-edit-data-order-master",
        { id: this.detail_ID, remark: this.edit_detail_remark },
        { headers: this.header_token }
      );
      if (response.data.status) {
        this.$swal({
          toast: true,
          timer: 1500,
          showConfirmButton: false,
          position: "top",
          type: "success",
          title: "แก้ไข หมายเหตุ เรียบร้อย",
        });
        this.can_edit_remark = false;
        this.edit_detail_remark = null;
        this.ShowDetailOrder_tmp(this.detail_ID);
        this.loading = false;
      }
      this.loading = false;
    },

    async shop_change_shipping_status() {
      this.loading = true;
      let response = await axios.post(
        orderService_dotnet + "Order/change-order-status",
        {
          orderID: this.detail_ID,
          shippingStatusID: this.detail_shiping_staus_update_select,
          staffShopID: localStorage.getItem("staff_id"),
        },
        { headers: this.header_token }
      );
      if (response.data.status == "success") {
        this.$swal({
          toast: true,
          timer: 1500,
          showConfirmButton: false,
          position: "top",
          type: "success",
          title: "บันทึกข้อมูลเรียบร้อย",
        });
        this.ShowDetailOrder_tmp(this.detail_ID);
        this.loading = false;
      } else {
        this.loading = false;
      }
    },

    copyTrackingCode() {
      if (this.detail_ExpressCompanyID == 2) {
        window.open(
          "https://th.kerryexpress.com/en/track/?track=" + this.detail_TrackingNo
        );
      } else if (this.detail_ExpressCompanyID == 3) {
        window.open("https://track.thailandpost.co.th/");
      } else if (this.detail_ExpressCompanyID == 4) {
        window.open("https://track.thailandpost.co.th/");
      } else if (this.detail_ExpressCompanyID == 5) {
        window.open("https://track.thailandpost.co.th/");
      } else if (this.detail_ExpressCompanyID == 6) {
        window.open("https://www.nimexpress.com/web/p/tracking");
      } else if (this.detail_ExpressCompanyID == 10) {
        window.open("https://www.scgexpress.co.th/tracking");
      } else if (this.detail_ExpressCompanyID == 11) {
        window.open("https://www.jtexpress.co.th/index/query/gzquery.html");
      } else if (this.detail_ExpressCompanyID == 12) {
        window.open("https://www.ninjavan.co/th-th/tracking");
      } else if (this.detail_ExpressCompanyID == 13) {
        window.open("https://www.dhl.com/th-th/home/tracking.html");
      }
    },

    image_click(photo_link) {
      this.dialog_photo_link = photo_link;
      this.dialog_photo = true;
    },

    deleteShipping() {
      this.$swal({
        position: "top",
        type: "warning",
        title: "ต้องยกเลิก Order นี้ใช่หรือไม่ !!",
        // text: this.myKeyword.textD1[this.language]+' '+ name +' '+ this.myKeyword.textD2[this.language],
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          let response = await axios.post(
            orderService_dotnet + "Order/cancel-order",
            {
              orderID: this.detail_ID,
              staffShopID: localStorage.getItem("staff_id"),
              staffBranchID: null,
            },
            { headers: this.header_token }
          );
          if (response.data.status) {
            this.$swal({
              toast: true,
              timer: 1500,
              showConfirmButton: false,
              position: "top",
              type: "success",
              title: "ยกเลิกสำเร็จ",
            });

            this.ShowDetailOrder_tmp(this.detail_ID);
          } else {
            this.$swal({
              type: "warning",
              title: "มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!",
            });
          }
        }
      });
    },

    async getDataShippingAddressManageID(addressID) {
      this.loading = true;

      let res2 = await axios.post(
        shopService_dotnet + "Shop/get-shipping-address-list",
        { id: addressID, isAscending: true },
        { headers: this.header_token }
      );
      if (res2.data.length > 0) {
        var ShipAddressManage = res2.data[0];
        this.ShipAddressManage.ID = ShipAddressManage.id;
        this.ShipAddressManage.Name = ShipAddressManage.name;
        this.ShipAddressManage.Phone = ShipAddressManage.phone;
        this.ShipAddressManage.TextAddress = ShipAddressManage.address;
        this.ShipAddressManage.ShopCustomerID = ShipAddressManage.shopCustomerID;

        if (ShipAddressManage["postcodeMappingId"] != null) {
          for (var i = 0; i < this.postcode_mapping.length; i++) {
            if (
              this.postcode_mapping[i]["Id"] == ShipAddressManage["postcodeMappingId"]
            ) {
              this.ShipAddressManage.PostcodeMappingID =
                ShipAddressManage["postcodeMappingId"];
              this.ShipAddressManage.Address = {
                Id: this.postcode_mapping[i]["Id"],
                SubDistrict: this.postcode_mapping[i]["SubDistrict"],
                District: this.postcode_mapping[i]["District"],
                Province: this.postcode_mapping[i]["Province"],
                Postcode: this.postcode_mapping[i]["Postcode"],
              };
              break;
            }
          }
        } else {
          this.ShipAddressManage.Address = {
            Id: ShipAddressManage["postcodeMappingId"],
            SubDistrict: ShipAddressManage["subDistrict"],
            District: ShipAddressManage["district"],
            Province: ShipAddressManage["province"],
            Postcode: ShipAddressManage["postcode"],
          };
        }
      }

      this.loading = false;
    },

    async EditDataShippingAddressManageID() {
      // var ShipAddressManage_tmp = 0
      // if(this.ShipAddressManage.ID != null){
      //   ShipAddressManage_tmp = this.ShipAddressManage.ID
      // }
      if (this.ShipAddressManage.ID != null) {
        this.loading = true;
        await axios
          .all([
            axios.post(
              shopService_dotnet + "Customer/update-customer-address",
              {
                id: this.ShipAddressManage.ID,
                shopCustomerID: this.ShipAddressManage.ShopCustomerID,
                name: this.ShipAddressManage.Name,
                phone: this.ShipAddressManage.Phone,
                address: this.ShipAddressManage.TextAddress,
                postcodeMappingID: this.ShipAddressManage.PostcodeMappingID,
                isDefault: true,
              },
              { headers: this.header_token }
            ),
            axios.post(
              shopService_dotnet + "Customer/update-customer-info",
              {
                id: this.ShipAddressManage.ShopCustomerID,
                name: this.ShipAddressManage.Name,
                phone: this.ShipAddressManage.Phone,
                line: this.detail_edit_line,
                facebook: this.detail_edit_fackbook,
                email: this.detail_edit_email,
              },
              { headers: this.header_token }
            ),
          ])
          .then(
            axios.spread((res1, res2) => {
              this.can_edit_shipaddress = false;

              this.ShowDetailOrder_tmp(this.detail_ID);
            })
          );
        this.loading = false;
      } else {
        this.loading = true;
        let res2 = await axios.post(
          shopService_dotnet + "Customer/add-customer-address",
          {
            id: 0,
            shopID: localStorage.getItem("shop_id"),
            orderMasterID: this.detail_ID,
            shipName: this.ShipAddressManage.Name,
            shipPhone: this.ShipAddressManage.Phone,
            shipAddress: this.ShipAddressManage.TextAddress,
            shipPostcodeMappingID: this.ShipAddressManage.PostcodeMappingID,
            cusName: this.ShipAddressManage.Name,
            cusPhone: this.ShipAddressManage.Phone,
            cusFacebook: this.detail_edit_fackbook,
            cusLine: this.detail_edit_line,
            cusEmail: this.detail_edit_email,
          },
          { headers: this.header_token }
        );
        this.loading = false;
        this.can_edit_shipaddress = false;
        this.ShowDetailOrder_tmp(this.detail_ID);
      }
    },

    async get_bank_cod() {
      this.loading = true;
      let res2 = await axios.post(
        shopService_dotnet + "Shop/get-shop-bank-list",
        { shopID: this.shop_id },
        { headers: this.header_token }
      );
      var bank_list_tmp = [];
      if (res2.data != null && res2.data.length != 0) {
        for (var i = 0; i < res2.data.length; i++) {
          var back_dict = {
            ID: res2.data[i]["id"],
            text: res2.data[i]["fullBankName"],
          };
          bank_list_tmp.push(back_dict);
        }
      } else {
        this.cod_bank_data = [];
      }

      this.cod_bank_data = bank_list_tmp;
      this.loading = false;
    },

    async add_data_list_payment() {
      if (this.edit_cod_bank == null) {
        alert("กรุณาเลือกธนาคาร");
        return;
      } else if (this.input_transferFileUpload == null) {
        this.$refs.input_transferFileUpload.focus();
        return;
      } else if (this.input_transferAmount == null) {
        this.$refs.input_transferAmount.focus();
        return;
      } else if (this.select_transferDate == null) {
        this.$refs.select_transferDate.focus();
        return;
      } else if (this.select_transferHour == null) {
        this.$refs.select_transferHour.focus();
        return;
      } else if (this.select_transferMinute == null) {
        this.$refs.select_transferMinute.focus();
        return;
      }

      var fileToBase64 = null;
      const toBase64 = (file) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });

      if (this.input_transferFileUpload != null) {
        fileToBase64 = await toBase64(this.input_transferFileUpload);
      } else {
        fileToBase64 = null;
      }

      var informDatetime_tmp = new Date(
        this.select_transferDate +
          " " +
          this.select_transferHour +
          ":" +
          this.select_transferMinute +
          ":" +
          "00"
      );
      const _date = new Date(informDatetime_tmp);
      informDatetime_tmp = new Date(_date.getTime() + 7 * (60 * 60 * 1000)).toISOString();

      // var data_list_payment = []
      var payment_list = {
        id: this.data_list_payment.length + 1,
        orderMasterId: this.detail_ID,
        bankAccountText: this.bank_id_to_text(this.edit_cod_bank),
        amount: parseFloat(Number(this.input_transferAmount).toFixed(2)),
        photoLink: fileToBase64,
        informDatetime: informDatetime_tmp,
        createdDatetime: new Date(Date.now() + 7 * (60 * 60 * 1000)).toISOString(),
        approved: null,
        approvedBy: null,
        disApproved: null,
        disApprovedBy: null,
        updateDateTime: null,
      };

      this.data_list_payment.push(payment_list);

      // เคลียข้อมูล
      this.edit_cod_bank = null;
      this.input_transferFileUpload = null;
      this.input_transferAmount = null;
      this.select_transferDate = null;
      this.select_transferHour = null;
      this.select_transferMinute = null;
    },

    delete_data_list_payment(id) {
      for (var e = 0; e < this.data_list_payment.length; e++) {
        if (this.data_list_payment[e]["id"] == id) {
          this.data_list_payment.splice(e, 1);
          break;
        }
      }
    },

    async upload_data_payment() {
      if (this.data_list_payment.length > 0) {
        this.loading = true;
        let response = await axios.post(
          orderService_dotnet + "Order/detail-order-add-order-payment",
          {
            orderPaymentList: this.data_list_payment,
          },
          { headers: this.header_token }
        );
        if (response.data.status) {
          this.data_list_payment = [];
          this.can_edit_data_cod = false;
          this.dialog_add_slip = false;

          this.ShowDetailOrder_tmp(this.detail_ID);
        }
        this.loading = false;
      }
    },

    async order_update_iscod_status() {
      var codAmount = null;
      var shopBankAccountID = null;
      if (this.cod_list_select == 1) {
        if (this.edit_cod_amount == null || this.edit_cod_amount == "") {
          this.$refs.edit_cod_amount.focus();
          return;
        } else if (this.edit_cod_bank == null) {
          alert("กรุณาเลือกธนาคาร !!");
          return;
        }
        codAmount = this.edit_cod_amount;
        shopBankAccountID = this.edit_cod_bank;
      }

      this.loading = true;
      let response = await axios.post(
        orderService_dotnet + "Order/detail-order-edit-status-iscod",
        {
          orderMasterID: this.detail_ID,
          isCOD: this.cod_list_select,
          codAmount: codAmount,
          shopBankAccountID: shopBankAccountID,
        },
        { headers: this.header_token }
      );
      if (response.data.status) {
        this.can_edit_data_cod = false;

        this.ShowDetailOrder_tmp(this.detail_ID);
      }
      this.loading = false;
    },

    async delete_data_payment(payment_id) {
      this.loading = false;
      let response = await axios.post(
        orderService_dotnet + "Order/detail-order-delete-order-payment",
        {
          id: payment_id,
        },
        { headers: this.header_token }
      );
      if (response.data.status) {
        this.ShowDetailOrder_tmp(this.detail_ID);
      }
      this.loading = false;
    },

    async get_data_stock_for_edit() {
      this.detailOrderProduct_tmp = [...this.detailOrderProduct];

      this.vat_percent = JSON.parse(JSON.stringify(this.vat_percent_tmp));
      this.textField_deliveryCost = JSON.parse(
        JSON.stringify(this.textField_deliveryCost_tmp)
      );
      this.select_discountType = JSON.parse(JSON.stringify(this.select_discountType_tmp));
      this.textField_discount = JSON.parse(JSON.stringify(this.textField_discount_tmp));
      this.select_vatType = JSON.parse(JSON.stringify(this.select_vatType_tmp));

      this.calculateTotalStockPrice();
      this.can_edit_product = true;
      // this.dialog_edit_stock = true
    },

    async changePage() {
      if (this.pageLength != 1 && this.pageTemp != this.page) {
        this.offsets = this.page * this.limits - this.limits;
        this.loading = true;
        await this.getSelectProduct(
          this.limits,
          this.offsets,
          this.Popupsearch,
          this.page
        );
        this.pageTemp = this.page;
      }
    },
    async PopupsearchStock(limits, offsets) {
      if (this.searchStockModelPopup != null) {
        this.Popupsearch = this.searchStockModelPopup.trim();
        this.loading = true;
        if (this.Popupsearch.length > 0) {
          this.getSelectProduct(limits, offsets, this.Popupsearch, 1);
        } else {
          this.getSelectProduct(limits, offsets, null, 1);
        }
      }
    },
    async OpenPopup(limits, offsets) {
      this.loading = true;
      this.searchStockModelPopup = null;
      this.addOrderPopup.Select_Product = [];
      this.addOrderPopup.Data = [];
      // this.addOrderPopup.ProductID_List = []
      this.addOrderPopup.loading = false;
      this.Popupsearch = null;
      for (var i in this.detailOrderProduct_tmp) {
        if (
          !this.addOrderPopup.ProductID_List.includes(
            this.detailOrderProduct_tmp[i]["stockShopId"]
          )
        ) {
          if (this.detailOrderProduct_tmp[i]["stockShopId"] != null) {
            this.addOrderPopup.ProductID_List.push(
              this.detailOrderProduct_tmp[i]["stockShopId"]
            );
          }
        }
      }

      this.page = 1;
      this.pageTemp = 1;
      this.getSelectProduct(limits, offsets, this.searchStockModelPopup, this.page);
    },
    async getSelectProduct(limits, offsets, searchstock, page) {
      this.loading = true;
      let response = await axios.post(
        stockService_dotnet + "Stock/get-stock-list-addorder",
        {
          shopID: this.shop_id,
          keyword: searchstock,
          warehouseID: this.Warehouse.id,
          skipStockShopIDList: this.addOrderPopup.ProductID_List,
          skip: offsets,
          take: limits,
        },
        { headers: this.header_token }
      );

      if (response.status == 200) {
        this.pageLength = Math.ceil(response.data.resultCount / this.limits);
        if (this.pageLength <= 0) {
          this.pageLength = 1;
        }
        this.page = page;
        this.pageTemp = page;

        var StockShopID_list = [];
        if (this.size == "xs" || this.size == "sm") {
          // await this.SetUp_Product()
          // var StockShopID_list = []

          for (var a in response.data.stockList) {
            response.data.stockList[a]["quantityAvailable"] =
              response.data.stockList[a]["quantity"];
            response.data.stockList[a]["unitPrice"] =
              response.data.stockList[a]["salePrice"];
            response.data.stockList[a]["prop1Description"] =
              response.data.stockList[a]["pro1_Description"];
            response.data.stockList[a]["prop2Description"] =
              response.data.stockList[a]["pro2_Description"];
            if (StockShopID_list.includes(response.data.stockList[a]["id"])) {
              response.data.stockList[a]["selectProduct"] = true;
            } else {
              response.data.stockList[a]["selectProduct"] = false;
            }
          }
        } else {
          for (var b in response.data.stockList) {
            response.data.stockList[b]["quantityAvailable"] =
              response.data.stockList[b]["quantity"];
            response.data.stockList[b]["unitPrice"] =
              response.data.stockList[b]["salePrice"];
            response.data.stockList[b]["prop1Description"] =
              response.data.stockList[b]["pro1_Description"];
            response.data.stockList[b]["prop2Description"] =
              response.data.stockList[b]["pro2_Description"];
          }
        }
        this.addOrderPopup.Data = response.data.stockList;
        for (var i in this.addOrderPopup.Select_Product) {
          StockShopID_list.push(this.addOrderPopup.Select_Product[i]["id"]);
        }
        this.loading = false;
        this.addOrderPopup.Dialog = true;
      } else {
        this.loading = false;
        this.$swal({
          position: "top",
          type: "error",
          text: "ไม่สามารถทำรายการได้",
        });
      }
    },
    ProductSelect(item, index) {
      this.addOrderPopup.Data[index]["selectProduct"] = !item["selectProduct"];
      if (item["selectProduct"] == true) {
        //นำเข้า Select_Product
        this.addOrderPopup.Select_Product.splice(0, 0, item);
      } else {
        //นำออก Select_Product
        var data = this.addOrderPopup.Select_Product;
        for (var i in data) {
          if (data[i]["id"] == item["id"]) {
            this.addOrderPopup.Select_Product.splice(i, 1);
            break;
          }
        }
      }
    },
    ProductSelectCheckbox(item, index) {
      if (item["selectProduct"]) {
        //นำเข้า Select_Product
        this.addOrderPopup.Select_Product.splice(0, 0, item);
      } else {
        //นำออก Select_Product
        var data = this.addOrderPopup.Select_Product;
        for (var i in data) {
          if (data[i]["id"] == item["id"]) {
            this.addOrderPopup.Select_Product.splice(i, 1);
            break;
          }
        }
      }
    },
    SetUp_Product() {
      var StockShopID_list = [];
      for (var i in this.addOrderPopup.Select_Product) {
        StockShopID_list.push(this.addOrderPopup.Select_Product[i]["id"]);
      }
      for (var a in this.addOrderPopup.Data) {
        if (StockShopID_list.includes(this.addOrderPopup.Data[a]["id"])) {
          this.addOrderPopup.Data[a]["selectProduct"] = true;
        } else {
          this.addOrderPopup.Data[a]["selectProduct"] = false;
        }
      }
    },
    async Submit_addOrderPopup() {
      this.addOrderPopup.loading = true;
      for (var i in this.addOrderPopup.Select_Product) {
        await this.searchStockModel_push(this.addOrderPopup.Select_Product[i]);
      }
      this.addOrderPopup.loading = false;
      this.addOrderPopup.Dialog = false;
    },
    async searchStockModel_push(value) {
      if (value != null) {
        var IsHave = false;
        for (var i1 = 0; i1 < this.detailOrderProduct_tmp.length; i1++) {
          if (value.isProductSet == true || value.isProductSet == 1) {
            for (var i2 = 0; i2 < this.detailOrderProduct_tmp.length; i2++) {
              if (
                (this.detailOrderProduct_tmp[i2].isProductSet == true ||
                  this.detailOrderProduct_tmp[i2].isProductSet == 1) &&
                this.detailOrderProduct_tmp[i2].productMasterId == value.productMasterId
              ) {
                IsHave = true;
                break;
              }
            }
          } else {
            if (this.detailOrderProduct_tmp[i1].id == value.id) {
              IsHave = true;
              break;
            }
          }
        }

        if (IsHave == false) {
          var data_set = {
            orderItemTempID: null,
            discount: null,
            discountPercent: null,
            productName: value["name"],
            orderMasterId: this.detail_ID,
            photoLink: value["photoLink"],
            quantity: 1,
            // "stockID": value['ID'],
            stockShopId: value["id"],
            unitPrice: value["unitPrice"],
            prop1Description: value["prop1Description"],
            prop2Description: value["prop2Description"],
            itemId: null,
            isProductSet: value["isProductSet"],
            productMasterId: value["productMasterId"],
            productMasterItemId: value["productMasterItemId"],
          };
          // this.productList.push(value)
          this.detailOrderProduct_tmp.push(data_set);
          // this.detailOrderProduct_tmp_insert.push(data_set)

          this.calculateTotalStockPrice();
        }
      }
    },

    async get_v_stock_shop_by_id() {
      if (this.detailOrderProduct_tmp.length == 0) return;
      this.addOrderPopup.ProductID_List = [];
      for (var d = 0; d < this.detailOrderProduct_tmp.length; d++) {
        this.addOrderPopup.ProductID_List.push(
          this.detailOrderProduct_tmp[d]["stockShopId"]
        );
      }
    },

    copyToken() {
      // this.detail_Token
      let TokenCodeToCopy = document.querySelector("#testing-code");
      TokenCodeToCopy.setAttribute("type", "text"); // 不是 hidden 才能複製
      TokenCodeToCopy.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "สำเร็จ" : "ไม่สำเร็จ";
        this.$swal({
          toast: true,
          timer: 1500,
          showConfirmButton: false,
          position: "top",
          type: "success",
          title: "คัดลอก" + msg,
        });
      } catch (err) {
        this.$swal({
          type: "warning",
          title: "เกิดข้อผิดพลาดบางอย่าง",
          text: "คัดลอกไม่สำเร็จ !!",
        });
      }

      /* unselect the range */
      TokenCodeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },

    set_zero(num) {
      num = parseInt(num);
      if (num < 10) {
        num = "0" + num.toString();
      } else {
        num = num.toString();
      }
      return num;
    },

    status_eng_to_thai(test_eng) {
      if (test_eng != null) {
        if (test_eng == "shipping-from-chinese-shop") {
          return "ร้านค้าจีนส่งมา";
        } else if (test_eng == "arrive-at-chinese-warehouse") {
          return "ถึงโกดังจีน";
        } else if (test_eng == "shipping-from-chinese-warehouse") {
          return "ส่งออกจากโกดังจีน";
        } else if (test_eng == "arrive-at-thai-warehouse") {
          return "ถึงโกดังไทย";
        }
      } else {
        return "";
      }
    },
    CalculateTimeUsed() {
      if (
        this.detail_order.startPackingDate != null &&
        this.detail_order.packedDate != null
      ) {
        var date1 = new Date(this.detail_order.startPackingDate);
        var date2 = new Date(this.detail_order.packedDate);

        var diff = date2 - date1;

        var msec = diff;
        var hh = Math.floor(msec / 1000 / 60 / 60);
        msec -= hh * 1000 * 60 * 60;
        var mm = Math.floor(msec / 1000 / 60);
        msec -= mm * 1000 * 60;
        var ss = Math.floor(msec / 1000);
        msec -= ss * 1000;
        return hh + " ชั่วโมง, " + mm + " นาที, " + ss + " วินาที";
      } else {
        return "";
      }
    },
    openMhaoDialog(MhaoGuidID, mh_line_item_id, row_id, status) {
      var date = new Date().toISOString().substr(0, 10);
      var current = new Date();
      var status_convert = null;
      if (status == "สั่งแล้ว") {
        status_convert = 2;
      }
      if (status == "รอรับสินค้า") {
        status_convert = 3;
      }
      if (status == "สำเร็จ") {
        status_convert = 4;
      }
      this.mh_selectStatus = status_convert;
      this.MhaoGuidID = MhaoGuidID;
      this.row_id = row_id;
      this.startDate = date;
      this.mh_line_item_id = mh_line_item_id;
      this.mhaoDialog = true;
      this.time =
        this.set_zero(current.getHours().toString()) +
        ":" +
        this.set_zero(current.getMinutes().toString());
    },

    img_express_return(express_id) {
      if (express_id != null) {
        if (express_id == 1) {
          return "https://packhaistorage.s3-ap-southeast-1.amazonaws.com/image/BESTSmallLogo.JPG";
        } else if (express_id == 2) {
          return "https://packhaistorage.s3-ap-southeast-1.amazonaws.com/image/kerry_ExpressLogo.png";
        } else if (express_id == 3) {
          return "https://packhaistorage.s3-ap-southeast-1.amazonaws.com/image/EMSSmallLogo.JPG";
        } else if (express_id == 4) {
          return "https://packhaistorage.s3-ap-southeast-1.amazonaws.com/image/thaipost.jpg";
        } else if (express_id == 5) {
          return "https://packhaistorage.s3-ap-southeast-1.amazonaws.com/image/thaipost.jpg";
        } else if (express_id == 6) {
          return "https://packhaistorage.s3-ap-southeast-1.amazonaws.com/image/NimSmallLogo.JPG";
        } else if (express_id == 7) {
          return "https://packhaistorage.s3-ap-southeast-1.amazonaws.com/image/FlashSmallLogo.JPG";
        } else if (express_id == 8) {
          return "https://packhaistorage.s3-ap-southeast-1.amazonaws.com/image/shopee.png";
        } else if (express_id == 9) {
          return "https://packhaistorage.s3-ap-southeast-1.amazonaws.com/image/lazada.png";
        } else if (express_id == 10) {
          return "https://packhaistorage.s3-ap-southeast-1.amazonaws.com/image/SCGSmallLogo.JPG";
        } else if (express_id == 11) {
          return "https://packhaistorage.s3-ap-southeast-1.amazonaws.com/image/JandTSmallLogo.png";
        } else if (express_id == 12) {
          return "https://packhaistorage.s3-ap-southeast-1.amazonaws.com/image/ninjavan_logo.png";
        } else if (express_id == 13) {
          return "https://packhaistorage.s3-ap-southeast-1.amazonaws.com/image/dhl.PNG";
        }
      } else {
        return null;
      }
    },

    async get_postcode_mapping() {
      var postcode_mapping = JSON.parse(localStorage.getItem("postcodeMapping_dotnet"));
      if (postcode_mapping === "undefined") {
        postcode_mapping = null;
      } else if (postcode_mapping == "null") {
        postcode_mapping = null;
      } else if (postcode_mapping == null) {
        postcode_mapping = null;
      } else if (postcode_mapping == "") {
        postcode_mapping = null;
      }
      if (postcode_mapping != null) {
        this.postcode_mapping = postcode_mapping;
      } else {
        await axios
          .post(
            generalService_dotnet + "General/get-postcodemapping",
            {},
            { headers: this.header_token }
          )
          .then((response) => {
            localStorage.setItem("postcodeMapping_dotnet", JSON.stringify(response.data));
            this.postcode_mapping = response.data;
          });
      }
    },

    open_dialog_update_item_mhao(item) {
      this.dialog_edit_item_mhao = true;
      this.edit_item_mhao_id = item["itemId"];
      this.edit_item_mhao_quantity = item["Quantity"];
      this.edit_item_mhao_unit_price = item["UnitPrice"];
    },

    CheckShippingStatus(expressCompanyId, trackingNo) {
      if (expressCompanyId == 2) {
        window.open("https://th.kerryexpress.com/en/track/?track=" + trackingNo);
      } else if (expressCompanyId == 3) {
        window.open("https://track.thailandpost.co.th/");
      } else if (expressCompanyId == 4) {
        window.open("https://track.thailandpost.co.th/");
      } else if (expressCompanyId == 5) {
        window.open("https://track.thailandpost.co.th/");
      } else if (expressCompanyId == 6) {
        window.open("https://www.nimexpress.com/web/p/tracking");
      } else if (expressCompanyId == 10) {
        window.open("https://www.scgexpress.co.th/tracking");
      } else if (expressCompanyId == 11) {
        window.open("https://www.jtexpress.co.th/index/query/gzquery.html");
      } else if (expressCompanyId == 12) {
        window.open("https://www.ninjavan.co/th-th/tracking");
      } else if (expressCompanyId == 13) {
        window.open("https://www.dhl.com/th-th/home/tracking.html");
      }
    },

    async check_stock_item_product_set(productMasterID, quantity) {
      // this.loading = true
      let response = await axios.post(
        stockService_dotnet + "Stock/check-stock-avalible-in-product-set",
        {
          productMasterID: productMasterID,
          quantity: quantity,
        },
        { headers: this.header_token }
      );
      // this.loading = false
      return response.data;
    },

    async update_data_pack_function() {
      try {
        this.loading = true;
        let response = await axios.post(
          orderService_dotnet + "Order/update-data-pack",
          {
            orderMasterId: this.detail_ID,
            packageId: this.update_data_pack.id,
            boxName: this.update_data_pack.boxName,
            weight: this.update_data_pack.weight,
            cubic1: this.update_data_pack.cubic1,
            cubic2: this.update_data_pack.cubic2,
            cubic3: this.update_data_pack.cubic3,
            boxPrice: this.update_data_pack.boxPrice,
            packPrice: this.update_data_pack.packPrice,
            deliveryPrice: this.update_data_pack.deliveryPrice,
            codPercent: this.update_data_pack.codPercent,
            codCharged: this.update_data_pack.codCharged,
            remotePrice: this.update_data_pack.remotePrice,
            discount: this.update_data_pack.discount,
            branchBoxCost: this.update_data_pack.branchBoxCost,
            branchCODChargedCost: this.update_data_pack.branchCODChargedCost,
            branchDeliveryCost: this.update_data_pack.branchDeliveryCost,
          },
          { headers: this.header_token }
        );
        this.loading = false;
        this.edit_box_dialog=false;
        this.ShowDetailOrder_tmp(this.detail_ID);
        Swal.fire({
          position: "top",
          timer:1500,
          icon: "success",
          text: "บันทึกเรียบร้อย",
          toast:true
        });
      } catch (e) {
        this.loading = false;
        Swal.fire({
          position: "top",
          icon: "error",
          text: e.message,
        });
      }
    },

    async get_status_order_marketplace(order_id) {
      try {
        let response = await axios.get(
          ecomService_dotnet +
            "General/check-marketplace-order-by-id?OrderMasterID=" +
            order_id.toString(),
          { headers: this.header_token }
        );
        this.marketplace_status = response.data.data;
      } catch (e) {
        console.log(e.message);
        this.marketplace_status = null;
        // this.$swal({
        //   type: 'warning',
        //   title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
        //   text: e.message,
        // })
      }
    },
  },
};
</script>

<style scoped>
>>> .bex48-logo {
  width: 12.533vw;
  height: 11.2vw;
  display: block;
  /* margin: 8.8vw auto 1.6vw; */
  margin: 1.6vw auto 1.6vw;
}

>>> .slide-fade-enter-active {
  transition: all 0.3s ease;
}

>>> .slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

>>> .slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}

.packhai-border-table {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.packhai-border-table thead {
  color: white;
}

.packhai-border-table thead tr th {
  font-weight: bold;
  font-size: 1rem;
}

.return-order-table {
  width: 100%;
}

.padding-for-order-dialog {
  padding: 0px !important ;
}

@media screen and (min-width: 768px) {
  .return-order-table {
    width: 300px;
  }

  .padding-for-order-dialog {
    padding: 15px !important;
  }
}

.packhai-border-preview {
  border-top: solid 1px black;
  border-left: solid 1px black;
  border-right: solid 1px black;
  border-bottom: solid 1px black;
  border-radius: 10px;
}

.px-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.fz-12 {
  font-size: 12px !important;
}

.fz-14 {
  font-size: 14px !important;
}
</style>
